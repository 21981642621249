@import 'styles/utils/index';

.hubspot-form-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: rem(4px);

  .hubspot-form {
    display: flex;
    border: 1px solid $grey-200;
    align-items: center;
    width: rem(560px);
    padding: rem(65px 50px 45px);

    @include mq($until: tablet) {
      border: none;
      padding: 0 rem(20px);
      width: auto;
    }

    @include mq($until: iphone-11) {
      padding: 0;
    }
  }
}

.hubspot-form-loading {
  padding: rem(65px 50px 45px);
  width: rem(590px);
  position: relative;
  height: rem(620px);
}

.hidden {
  display: none;
}
