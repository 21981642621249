@import 'styles/utils/index';

.enrichment-wrapper {
  color: $font-3;

  .container {
    margin: 0 auto;

    @include mq($until: desktop) {
      padding: rem(0 15px);
    }

    p {
      margin: 0;
    }

    .enrichment-title {
      margin: rem(64px auto);
      text-align: center;

      h1 {
        font-size: rem(56px);
        margin: 0;
        color: $font-4;
      }

      h2 {
        font-weight: 500;
        font-size: rem(24px);
        line-height: rem(29px);
        max-width: rem(700px);
        margin: rem(20px auto 0);
      }
    }
  }
}
