@import 'styles/utils/index';

.our-clients-wrapper {
  background: $secondary-500;
  color: $white;
  padding: rem(124px 0 164px);

  .our-clients-container {
    max-width: rem(1250px);
    width: 100%;
    margin: 0 auto;
    padding-left: rem(15px);
    padding-right: rem(15px);

    h2 {
      font-weight: 700;
      font-size: 48px;
      line-height: 56px;
      margin: rem(0 0 12px);
      text-align: left;

      @include mq($until: small-desktop) {
        margin: rem(0 auto 16px);
        text-align: center;
      }

      @include mq($until: iphone-11) {
        text-align: left;
      }
    }

    .our-clients-rating {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: rem(70px);
      gap: rem(25px);

      svg {
        font-size: rem(110px);
      }
    }

    .our-clients-description {
      .our-clients-inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: rem(56px);

        @include mq($until: small-desktop) {
          display: block;
        }

        @include mq($until: iphone-11) {
          margin-bottom: rem(80px);
        }
      }

      p {
        max-width: rem(700px);
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        width: 100%;
        margin-right: rem(40px);

        @include mq($until: small-desktop) {
          margin: rem(0 auto 24px);
          text-align: center;
        }

        @include mq($until: iphone-11) {
          text-align: left;
        }
      }

      button {
        @include mq($until: small-desktop) {
          display: block;
          margin: rem(40px auto 0);
        }

        @include mq($until: iphone-11) {
          margin-left: 0;
        }
      }

      .our-clients-position {
        justify-content: space-around;
      }
    }
  }
}
