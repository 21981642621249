@import 'styles/utils/index';

.switch {
  margin: 0 16px;

  input[type='checkbox'] {
    display: none;
  }

  label {
    cursor: pointer;
    background: $white;
    display: block;
    position: relative;
    width: 48px;
    height: 20px;
    border: 1px solid $secondary-500;
    border-radius: 24px;

    &:after {
      content: '';
      position: absolute;
      background: $secondary-500;
      border-radius: 90px;
      transition: 0.3s;
      width: 14px;
      height: 14px;
      left: 2px;
      top: 2px;
    }

    &:active:after {
      width: 22px;
    }
  }

  input {
    &:checked + label {
      background: $secondary-500;
    }

    &:checked + label:after {
      background: $white;
    }

    &:checked + label:after {
      left: calc(100% - 2px);
      transform: translateX(-100%);
    }
  }
}
