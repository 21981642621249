@import 'styles/utils/index';

.companies-upload {
  border: 1px solid $grey-200;
  box-shadow: rem(0) rem(7px) rem(27px) rem(3px) rgba(31, 105, 179, 0.03);
  max-width: rem($layout-base-2);
  margin: 0 auto;
  border-radius: 7px;
  padding: rem(60px);
  width: 100%;

  @include mq($until: tablet) {
    padding: rem(20px);
  }

  .companies-upload_stage {
    margin-bottom: rem(40px);

    .companies-upload_title {
      display: flex;
      align-items: center;
      margin-bottom: rem(10px);
      font-size: rem(18px);
      font-weight: 700;
      color: $font-4;

      p:first-child {
        margin-right: rem(6px);
      }
    }

    .companies-upload_desc {
      margin-bottom: rem(8px);
    }

    .companies-upload_dropzone {
      max-width: rem(550px);

      span {
        max-width: rem(300px);
        font-size: rem(14px);
      }
    }

    .companies-upload-desc {
      margin-top: rem(18px) !important;
    }

    .companies-upload_scores {
      display: flex;
      flex-direction: column;
      margin: rem(20px 0);
      grid-row-gap: rem(16px);
    }

    .companies-upload_tags {
      display: flex;
      overflow-x: auto;
      width: 100%;
      padding-bottom: rem(16px);
    }

    &.inactive {
      color: $font-2;
      margin-bottom: rem(20px);

      p {
        cursor: not-allowed;
      }
    }
  }
}
