@import 'styles/utils/index';

.vacancy-description-wrapper {
  margin-top: rem(48px);
  width: 100%;

  h5 {
    font-weight: 700;
    font-size: rem(24px);
    line-height: rem(32px);
    color: $font-4;
    margin-bottom: 0px;

    @include mq($until: iphone-11) {
      font-size: rem(20px);
      line-height: rem(24px);
    }
  }

  .rich-text {
    font-weight: 400;
    font-size: rem(18px);
    line-height: rem(28px);
    color: $font-3;
    margin-top: rem(16px);

    ul {
      list-style-type: disc;
      list-style-position: inside;

      li {
        margin-bottom: rem(12px);
      }
    }
  }
}
