@import 'styles/utils/index';

.video-preview-presentation {
  height: 100%;
  max-height: 456px; /** keep it in pixels so iframe's height can be the same */
  min-height: 456px;
  width: 100%;
  position: relative;

  .video-preview {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    height: 100%;
    width: 100%;

    div {
      height: 100%;
    }

    &.hidden {
      display: none;
    }

    .video-preview-bg {
      position: absolute;
      border-radius: rem(2px);
      background: $secondary-800;
      opacity: 0.4;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }

    .video-preview-button {
      border-radius: rem(2px);
      position: absolute;
      max-width: 60px;
      max-height: 48px !important;
      min-width: auto;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      padding: rem(0 16px);
    }

    img {
      width: 100%;
    }
  }
}
