@import 'styles/utils/index';

.progressbar-wrapper {
  .progressbar {
    background-color: $main-500;
    height: rem(2px);
    overflow: hidden;

    &-inner {
      width: 100%;
      background-color: $grey-100;
      height: 100%;
      display: block;
      transform: translate3D(-100%, 0, 0);
    }

    &-completed {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: rem(4px);

      span {
        font-weight: 400;
        font-size: rem(16px);
        line-height: rem(24px);
        color: $secondary-200;
      }
    }
  }
}
