@import 'styles/utils/index';

@keyframes pulse {
  0% {
    transform: translateX(rem(200px));
    opacity: 0;
    border-top-left-radius: 100%;
    border-bottom-left-radius: 100%;
  }

  50% {
    transform: translateX(rem(100px));
    opacity: 1;
    border-top-left-radius: 30%;
    border-bottom-left-radius: 30%;
  }

  100% {
    transform: translateX(0);
    border-top-left-radius: 0%;
    border-bottom-left-radius: 0%;
    border-right: 75px solid $grey-300;
    opacity: 0;
  }
}

.burger-menu-loading {
  position: fixed;
  right: 0;
  top: rem(40px);
  height: calc(100vh - #{rem(40px)});
  width: rem(200px);
  z-index: 9999;
  background-color: $grey-200;
  animation: pulse 1s infinite;
}
