@import 'styles/utils/index';

.search-wrapper {
  input:focus,
  input:active,
  input:hover,
  input {
    outline: none;
  }

  ::placeholder {
    color: #919dc7;
  }

  width: 100%;
  max-width: rem(800px);
  margin-left: auto;
  margin-right: auto;
  margin-bottom: rem(40px);
  position: relative;

  .search-form {
    display: flex;
    flex-direction: row;
  }

  .text-box {
    width: 100%;
    font-size: rem(18px);
    line-height: rem(28px);
    padding: 0px rem(24px);
    height: rem(64px);
    color: #919dc7;
    border: 1px solid #d8e5f7;
    border-radius: rem(2px) rem(0px) rem(0px) rem(2px);
    font-family: $font;
    font-weight: 400;

    &:hover,
    &:focus {
      border-color: $main-500;
    }
  }

  .search-button {
    border-radius: rem(0px) rem(2px) rem(2px) rem(0px);
  }

  .results-wrapper {
    margin-top: rem(8px);
    width: 100%;
    border-radius: rem(2px);
    padding: 0px rem(24px);
    box-shadow: rem(0px) rem(24px) rem(24px) rgba(13, 38, 127, 0.08);
    position: absolute;
    background: white;
    z-index: 10;
    max-height: rem(324px);
    overflow-y: scroll;
    min-height: rem(68px);

    &-extra-padding {
      @extend .results-wrapper;
      padding: rem(8px) rem(24px);
    }

    &::-webkit-scrollbar {
      width: 20px;
    }

    &::-webkit-scrollbar-thumb {
      background: #d8e5f7;
      border-radius: 10px;
      border: 8px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
    }
  }

  .results-number {
    font-family: $font;
    font-style: normal;
    font-weight: 400;
    font-size: rem(14px);
    line-height: rem(20px);
    color: #919dc7;

    padding: rem(22px) 0px;
  }

  hr {
    height: rem(1px);
    background-color: #ebf2fb;
    border: none;
    margin: 0px;
  }

  .result {
    padding: rem(12px) 0px;
    font-family: $font;
    font-style: normal;
    font-weight: 400;
    font-size: rem(18px);
    line-height: rem(28px);
    color: #425289;
    transition: 300ms;

    &:hover {
      color: #077be7;
    }
  }

  .search-icon-wrapper {
    color: #077be7;
    display: flex;
    align-items: center;
    height: rem(64px);
    right: rem(24px);
    position: absolute;
  }

  .search-icon {
    margin-left: rem(8px);
    width: rem(24px);
    height: rem(24px);
  }

  .search-icon-text {
    user-select: none;

    @include mq($until: small-tablet) {
      display: none;
    }
  }
}
