// Simple Color
$white: #ffffff;
$white-secondary: #f5f9fc;
$black: #000000;
$danger: #f72942;

// Brand / Main / Colors
$main-100: #ccf0fd !default;
$main-200: #9adcfc !default;
$main-300: #67c1f7 !default;
$main-400: #41a5f0 !default;
$main-500: #077be7 !default; // Main one
$main-600: #055fc6 !default;
$main-700: #0347a6 !default;
$main-800: #023285 !default;
$main-900: #01236e !default;

// Brand / Secondary / Colors
$secondary-100: #cbd9f8 !default;
$secondary-200: #9ab3f2 !default;
$secondary-300: #6281d8 !default;
$secondary-400: #3956b2 !default;
$secondary-500: #0c257f !default; // Main one
$secondary-600: #081c6d !default;
$secondary-700: #06145b !default;
$secondary-800: #030e49 !default;
$secondary-900: #02093c !default;

// Brand / Grey / Colors
$grey-000: #f9fbff !default;
$grey-100: #ebf2fb !default;
$grey-200: #d8e5f7 !default;
$grey-300: #bccde8 !default;
$grey-400: #a0b2d2 !default;
$grey-500: #7c8eb5 !default; // Main one
$grey-600: #5a6d9b !default;
$grey-700: #3e4f82 !default;
$grey-800: #273568 !default;
$grey-900: #172356 !default;

/** -- Critical colors -- */
$critical-light: #fceaea;
$critical-light-hover: #fbe0e0;
$critical-light-active: #f7bfbf;

$critical-normal: #e42f2f;
$critical-normal-hover: #cd2a2a;
$critical-normal-active: #b62626;

$critical-dark: #ab2323;
$critical-dark-hover: #891c1c;
$critical-dark-active: #671515;

$critical-darker: #501010;

/** -- Warning colors -- */
$warning-light: #fff7ed;
$warning-light-hover: #fef3e3;
$warning-light-active: #fde7c6;

$warning-normal: #fab247;
$warning-normal-hover: #e1a040;
$warning-normal-active: #c88e39;

$warning-dark: #bc8635;
$warning-dark-hover: #966b2b;
$warning-dark-active: #705020;

$warning-darker: #583e19;

/** -- Success colors -- */
$success-light: #ecfbf5;
$success-light-hover: #e2f8f0;
$success-light-active: #c3f1e1;

$success-normal: #3dd29d;
$success-normal-hover: #37bd8d;
$success-normal-active: #31a87e;

$success-dark: #2e9e76;
$success-dark-hover: #257e5e;
$success-dark-active: #1b5e47;

$success-darker: #154a37;

// Font / Colors
$font-1: #dee1ec !default;
$font-2: #afb6cd !default;
$font-3: #425289 !default;
$font-4: #071751 !default;
$font-5: #41a5f0 !default;

// Score colors
$score-a: #40ccad;
$score-b: #99e4d3;
$score-c: #f0bf40;
$score-d: #f9e6b3;
$score-e: #ec565c;
$score-n-a: #cbd6e2;
