@import 'styles/utils/index';

.career-card-link {
  text-decoration: none;
  outline: none;

  &:hover {
    text-decoration: none;

    .name {
      text-decoration: underline;
    }
  }

  &:focus .career-card-wrapper {
    @include focus;

    .name {
      text-decoration: underline;
    }

    .button-wrapper {
      .button {
        background-color: $main-500;
        transition: 500ms;

        svg {
          path {
            fill: white;
            transition: 500ms;
          }
        }
      }
    }
  }

  .career-card-wrapper {
    width: 100%;
    padding: rem(24px);
    background: $grey-000;
    border-radius: 2px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    transition: box-shadow;
    transition-duration: 300ms;
    margin-bottom: rem(24px);

    .details-wrapper {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      @include mq($until: small-desktop) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }

      font-size: rem(18px);
      line-height: rem(28px);
      font-weight: 400;

      .name {
        color: $main-500;
        width: 50%;

        @include mq($until: small-desktop) {
          width: 100%;
        }
      }

      .job-details {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        @include mq($until: small-desktop) {
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
        }

        color: $font-4;
        width: 50%;

        @include mq($until: small-desktop) {
          width: 100%;
        }

        .detail {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;

          .detail-icon {
            width: rem(24px);
            height: rem(24px);
            margin-right: rem(8px);
          }
        }
      }
    }

    .button-wrapper {
      margin-left: rem(120px);

      @include mq($until: small-desktop) {
        margin-left: 0px;
      }

      .button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: rem(32px);
        height: rem(32px);
        background-color: white;
        cursor: pointer;

        svg {
          path {
            fill: $font-2;
          }
        }

        .button-arrow {
          width: rem(16px);
          height: rem(16px);
        }
      }
    }

    &:hover {
      @extend .career-card-wrapper;
      box-shadow: 0px 12px 12px rgba(13, 38, 127, 0.08);
      transition: 300ms;

      .button-wrapper {
        .button {
          background-color: $main-500;
          transition: 500ms;

          svg {
            path {
              fill: white;
              transition: 500ms;
            }
          }
        }
      }
    }
  }
}
