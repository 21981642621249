@import 'styles/utils/index';

.select-loader__wrapper {
  width: 100%;
  margin: 0;
  @include skeleton;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: transparent;

  &.select-loader__md {
    height: rem(44px);
  }

  &.select-loader__lg {
    height: rem(64px);
  }
}
