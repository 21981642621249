@import 'styles/utils/index';

.feedback-skeleton {
  width: 100%;
  height: rem(932px);
  margin-top: rem(192px);
  @include skeleton;

  @include mq($until: desktop) {
    margin-top: rem(198px);
    height: rem(600px);
  }
}
