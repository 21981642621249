@import 'styles/utils/index';

.companies-table {
  .with-icon {
    display: flex;
    align-items: center;
  }

  .column-lower {
    text-transform: lowercase;
    font-size: rem(16px);

    svg {
      margin-right: rem(6px);
      fill: $grey-600;
      width: rem(14px);
    }
  }

  .column-score-data {
    display: block;
    text-align: center;
    border-radius: rem(4px);
    font-size: rem(16px);

    &-a {
      color: $score-a;
      border: 1px solid $score-a;
    }

    &-b {
      color: $score-b;
      border: 1px solid $score-b;
    }

    &-c {
      color: $score-c;
      border: 1px solid $score-c;
    }

    &-d {
      color: $score-d;
      border: 1px solid $score-d;
    }

    &-e {
      color: $score-e;
      border: 1px solid $score-e;
    }

    &-n-a {
      color: $score-n-a;
      border: 1px solid $score-n-a;
    }
  }

  .link {
    color: $main-600;
    text-transform: capitalize;
    margin-left: rem(4px);
  }

  &-actions {
    display: flex;
    justify-content: flex-end;

    > *:not(:last-child) {
      margin-right: rem(14px);
    }
  }

  .companies-table-view-more {
    margin: rem(25px) auto 0;
    display: block;
  }

  .skeleton {
    height: rem(24px);
    display: block;
    @include skeleton;
  }
}

.companies-table-email {
  display: inline-block !important;
  color: $grey-600;
  border-bottom: 1px solid $grey-600;
  cursor: default;
  margin-top: rem(10px);
  font-size: rem(17px);
}
