@import 'styles/utils/index';

.hero-text {
  color: $font-4;
  max-width: $layout-base-2;
  margin: rem(64px auto);
  padding-left: rem(24px);
  padding-right: rem(24px);

  &.center {
    text-align: center;
  }

  &.left {
    text-align: left;
  }

  &.right {
    text-align: right;
  }

  &.font {
    &-1 {
      color: $font-1;
    }

    &-2 {
      color: $font-2;
    }

    &-3 {
      color: $font-3;
    }

    &-4 {
      color: $font-4;
    }

    &-5 {
      color: $font-5;
    }
  }

  &.hero-text-sm {
    font-size: rem(24px);
    font-weight: 500;
    line-height: rem(29px);
  }

  &.hero-text-md {
    font-size: rem(32px);
    line-height: rem(40px);
  }

  &.hero-text-lg {
    font-size: rem(56px);
    line-height: rem(64px);
  }

  &.font-3 {
    color: $font-3;
  }
}
