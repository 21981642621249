@import 'styles/utils/index';

.agents-page-wrapper {
  .agents-page-container {
    max-width: calc(#{rem($layout-base-1)} + #{rem(30px)});
    padding-left: rem(15px);
    padding-right: rem(15px);
    margin: 0 auto;

    @include mq($until: desktop) {
      padding: rem(0 16px);
    }
  }
}
