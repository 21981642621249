@import 'styles/utils/index';

.tag-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
  margin: rem(16px 16px 0 0);
  border-radius: rem(2px);
  border: 1px solid $font-2;
  color: $font-2;
  white-space: nowrap;
  line-height: rem(20px);

  @include mq($until: small-tablet) {
    max-width: 100%;

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &_active {
    border: 1px solid $main-700;
    color: $main-700;
  }

  &_prefix {
    margin-right: rem(6px);
  }

  &_suffix {
    margin-left: rem(6px);
  }

  &_lg {
    padding: rem(19px);
  }

  &_md {
    padding: rem(11px 14px);
  }

  &_close {
    cursor: pointer;
    margin-left: rem(16px);
  }
}
