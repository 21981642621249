@import 'styles/utils/index';

.frequency-switch {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  max-width: rem(370px);
  padding: rem(60px 24px 0);

  span {
    color: $secondary-500;
    font-weight: 500;
    font-size: rem(16px);
    line-height: rem(20px);
    transition: 0.2s;
    cursor: pointer;
  }

  span:hover {
    color: $main-400;
  }

  .frequency-switch-discount-wrapper {
    position: absolute;
    top: rem(8px);
    right: rem(-33px);

    @include mq($until: small-desktop) {
      right: rem(-10px);
    }

    .frequency-switch-discount {
      position: relative;

      svg {
        font-size: rem(32px);
        position: absolute;
        left: rem(-32px);
        top: rem(12px);

        path {
          fill: $main-700;
        }
      }

      span {
        color: $white;
        background: $main-700;
        padding: rem(5px 9px);
        border-radius: rem(32px);
        font-weight: 400;
        font-size: rem(12px);
        line-height: rem(16px);
      }
    }
  }
}
