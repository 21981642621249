@import 'styles/utils/index';

.auth-template-skeleton-wrapper {
  margin: rem(120px auto 0);

  .auth-template-skeleton-title {
    color: transparent;
    width: max-content;
    margin: 0 auto rem(44px);
    @include skeleton;
    font-size: rem(56px);
  }

  .auth-template-skeleton-form {
    display: grid;
    grid-template-columns: auto;
    grid-row-gap: rem(24px);
    max-width: rem(500px);
    margin: 0 auto;

    .auth-template-skeleton-item {
      width: 100%;
      height: rem(66px);
      @include skeleton;
    }
  }

  .auth-template-skeleton-link {
    color: transparent;
    font-size: rem(14px);
    display: block;
    margin: rem(16px auto);
    width: max-content;
    @include skeleton;
  }
}
