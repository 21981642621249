@import 'styles/utils/index';

.slider-loader {
  width: 100%;
  @include skeleton;
  height: rem(400px);
  margin: rem(80px 0);

  @include mq($until: desktop) {
    height: rem(429px);
  }
}
