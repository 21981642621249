@import 'styles/utils/index';

.cards-customer {
  &-title {
    font-weight: 700;
    font-size: rem(32px);
    line-height: rem(40px);
    color: $font-4;
    text-align: center;
    margin: rem(164px 0 0);
  }
}
