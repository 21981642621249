@import 'styles/utils/index';

.customer-block {
  width: 100%;
  display: flex;
  max-width: rem($layout-base-1);
  margin: rem(64px) auto 0;
  justify-content: space-between;

  @include mq($until: desktop) {
    display: block;
  }

  .customer-block-slider-loader {
    margin: rem(64px) auto 0;
  }

  .customer-block__each-block {
    max-width: rem(384px);
    width: 100%;
    border: 1px solid $grey-100;
    border-radius: rem(2px);
    padding: rem(32px) rem(40px) rem(64px);
    background: $white;
    display: flex;
    flex-direction: column;

    .customer-block__rectangles {
      min-height: rem(87px);
    }

    .icon-rectangles-one {
      svg {
        font-size: rem(80px) !important;
        height: rem(80px);
        fill: none;
      }
    }

    .icon-rectangles-two {
      svg {
        font-size: rem(62px) !important;
        height: rem(80px);
        fill: none;
      }
    }

    .icon-rectangles-three {
      svg {
        font-size: rem(80px) !important;
        height: rem(80px);
        fill: none;
      }
    }

    .customer-block__text {
      height: 100%;
      margin-top: 0;

      > div {
        width: 100%;
      }
    }
  }

  h2 {
    margin: 0;
    font-size: rem(24px);
    min-height: rem(60px);
    margin-top: rem(8px);
    line-height: rem(29px);
  }

  .customer-block__info {
    flex-grow: 1;
    color: $font-3;
    line-height: rem($text-body-height-1);
    margin-top: rem(20px);
  }

  .customer-block__button {
    margin-top: rem(24px);

    a {
      padding: rem(15px) rem(20px);
    }
  }

  .slider-customer-block {
    display: flex;

    .customer-block__each-block {
      margin-bottom: rem(25px);

      &:not(:last-child) {
        margin-right: rem(30px);
      }

      @include mq($until: desktop) {
        min-width: rem(386px);
      }

      @include mq($until: tablet) {
        min-width: rem(320px);
      }

      @include mq($until: iphone-11) {
        max-width: rem(264px);
        padding: rem(20px);
        align-self: normal;
      }

      .customer-block__info {
        @include mq($until: desktop) {
          font-size: rem(16px);
          line-height: rem(24px);
        }
      }

      h2 {
        @include mq($until: iphone-11) {
          font-size: rem(20px);
          line-height: rem(24px);
        }
      }

      .customer-block__button {
        @include mq($until: tablet) {
          a {
            width: 100%;

            button {
              width: 100%;
            }
          }
        }

        @include mq($until: small-tablet) {
          margin-top: rem(8px);
        }
      }
    }
  }
}
