@import 'styles/utils/index';

.career-benefits-wrapper {
  background-color: $white-secondary;
  padding: rem(124px 32px 164px 32px);
  margin-top: rem(124px);
  margin-bottom: rem(-200px);

  .benefits-container {
    max-width: rem($layout-base-1);
    margin-right: auto;
    margin-left: auto;

    .benefits-info {
      max-width: rem($layout-base-3);
      margin-right: auto;
      margin-left: auto;

      h2 {
        font-weight: 700;
        font-size: rem(48px);
        line-height: rem(56px);
        text-align: center;
        color: $font-4;

        @include mq($until: iphone-11) {
          margin: rem(16px 0 12px);
          text-align: left;
          font-size: rem(40px);
          line-height: rem(44px);
        }
      }

      p {
        font-weight: 500;
        font-size: rem(20px);
        line-height: rem(28px);
        text-align: center;
        color: $font-3;

        @include mq($until: small-tablet) {
          font-size: rem(20px);
        }

        @include mq($until: iphone-11) {
          text-align: left;
          font-size: rem(16px);
          line-height: rem(20px);
        }
      }
    }

    .benefits {
      margin-top: rem(64px);
      width: 100%;
      display: grid;
      grid-auto-columns: minmax(0, 1fr);
      grid-auto-flow: column;

      @include mq($until: tablet) {
        display: block;
      }

      column-gap: rem(32px);
    }
  }
}
