@import 'styles/utils/index';

.cards-wrapper {
  max-width: rem(1210px);
  margin: rem(40px auto 64px);

  @include mq($until: wide) {
    padding: rem(0 15px);
  }

  .cards {
    display: flex;
    justify-content: center;
    gap: rem(25px);

    @include mq($until: desktop) {
      flex-direction: column;
    }

    .card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-radius: rem(2px);
      max-width: rem(384px);
      padding: rem(0 24px 40px);
      width: 100%;

      @include mq($until: wide) {
        &:not(:last-child) {
          margin-right: rem(30px);
        }
      }

      @include mq($until: desktop) {
        margin: 0 auto;
        max-width: 500px;

        &:not(:last-child) {
          margin-right: auto;
          margin-bottom: rem(30px);
        }
      }

      &-best {
        border: 1px solid $main-700;
        background: $white;

        h2 {
          color: $main-700 !important;
        }

        .card-header {
          background: $white;
        }
      }

      &-secondary {
        border: 1px solid $main-200;
        background: $white;

        .card-header {
          background: $white;
        }
      }

      &-ghost {
        border: 1px solid $grey-200;
        background: $grey-000;

        .card-header {
          background: $grey-000;
        }
      }

      > div {
        height: 100%;
      }

      p {
        color: $font-3;
        font-weight: 500;
        font-size: rem(16px);
        line-height: rem(28px);
      }

      .card-next-step {
        margin-top: rem(24px);
        width: 100%;
      }

      .card-link-trial {
        border: none;
        margin-top: rem(12px);
        text-decoration: underline;
        background-color: transparent;
        font-size: rem(16px);

        &:hover {
          background-color: transparent;
          box-shadow: none;
          color: $font-3;
        }

        &:active,
        &:focus {
          color: $font-3;
        }
      }

      .price-offer {
        color: $font-4 !important;
        font-weight: 500 !important;
        font-size: rem(20px) !important;
        margin: 0;
        line-height: rem(24px) !important;
      }

      span {
        color: $font-3;
        font-size: rem(18px);
      }

      .card-header {
        position: sticky;
        top: 0;
        padding-top: rem(40px);
        padding-bottom: rem(24px);
        z-index: 1;
      }

      .card-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: rem(0 0 8px);

        h2 {
          color: $font-4;
          font-weight: 700;
          font-size: rem(24px);
          line-height: rem(32px);
          margin: 0;
        }
      }

      .card-description {
        display: block;
        font-size: rem(18px);
        font-weight: 400;
        margin-bottom: rem(24px);
        line-height: rem(28px);
      }

      .card-discount {
        margin-top: rem(24px);
        display: flex;

        > *:not(:last-child) {
          margin-right: rem(8px);
        }

        @include mq($until: wide) {
          flex-direction: column;
          align-items: flex-start;
        }

        @include mq($until: desktop) {
          flex-direction: unset;
        }
      }
    }

    button:last-child {
      width: 100%;
    }
  }

  .cards-action {
    display: block;
    margin: rem(32px) auto;
  }
}
