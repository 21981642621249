@import 'styles/utils/index';

.loading {
  position: relative;

  &.loading-show .loading-children {
    opacity: 1;
  }

  .loading-children {
    opacity: 0;
    transition: opacity 0.5s;
  }

  .loading-spinner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    min-height: rem(200px);
    height: 100%;
    padding: 0;
    max-width: 100%;
  }
}
