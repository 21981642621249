@import 'styles/utils/index';

.course-stage-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: rem(72px);
  border-bottom: 1px solid $grey-200;
  margin-right: 0;
  cursor: pointer;

  &:hover p {
    transition: color 0.3s ease-out;
    color: $font-4;
  }

  &.active span:last-child,
  &.active p {
    color: $main-500;
  }

  .course-stage-viewed {
    color: #919dc7;
  }

  span,
  p {
    font-weight: 400;
    font-size: rem(16px);
    line-height: rem(24px);
    margin: 0;
  }

  p {
    margin-left: rem(8px);
    max-width: 240px;
  }

  & > h5 {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
  }

  span {
    justify-content: center;
    display: inline-flex;
    color: #919dc7;

    &:first-child {
      width: 100%;
      max-width: 24px;
    }

    &:last-child {
      margin-left: rem(15px);
    }
  }

  .course-stage-play {
    path {
      transform: translateX(-150%);
      animation: ani 0.3s forwards;
    }

    @keyframes ani {
      0% {
        transform: translateX(-150%);
      }
      100% {
        transform: translateX(0);
      }
    }
  }
}
