@import 'styles/utils/index';

.blog-note {
  margin: rem(0 auto 40px);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: rem(1100px);
  width: 100%;

  @include mq($until: tablet) {
    margin-top: rem(30px);
  }

  .blog-note-type {
    font-size: rem(12px);
    line-height: rem($text-body-font-2);
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: $main-500;
    opacity: 0.8;

    &:focus {
      @include focus;
      opacity: 1;
    }

    &:hover {
      opacity: 1;
    }
  }

  .blog-note-title {
    margin: rem(12px 0);
    line-height: rem(64px);
    color: $font-4;
    max-width: rem($layout-base-2);

    @include mq($until: tablet) {
      font-size: rem(35px);
      line-height: rem(40px);
    }

    &.no-author {
      margin-bottom: rem(54px);
    }
  }

  .blog-note-information {
    display: flex;
    align-items: center;
    margin-bottom: rem(54px);

    .blog-note-link {
      line-height: rem(20px);
      font-size: rem(14px);
      letter-spacing: 0.04em;
      color: $font-3;
      @include link-styling;
      margin-right: rem(4px);

      &:focus {
        @include focus;
      }

      &:hover span,
      &:focus span {
        color: $font-4;
      }

      span {
        color: $font-4;
        text-decoration: underline;
      }

      @include mq($until: iphone-11) {
        align-self: flex-start;
      }
    }

    .blog-note-published {
      color: $font-3;
      font-size: rem(14px);
      letter-spacing: rem(0.6px);
    }
  }

  .blog-note-image {
    @include mq($until: desktop) {
      width: 100%;
    }
  }

  .tabs-title {
    color: $font-4;
    font-size: rem(32px);
    line-height: rem(36px);
    margin: rem(0 0 32px 0);
  }

  .tabs-wrapper {
    display: flex;
    justify-content: space-between;
    margin: rem(0 0 24px);
    width: 100%;

    @include mq($until: 890px) {
      flex-direction: column;
      justify-content: center;
      margin: rem(0 auto 24px);
    }

    .blog-note-touch {
      @include mq($until: 890px) {
        align-self: center;
        text-align: center;

        &:not(:first-child) {
          margin-top: rem(32px);
        }
      }

      h2 {
        align-self: flex-start;

        b {
          font-weight: 600;
          color: $main-500;
        }

        @include mq($until: 890px) {
          align-self: center;
        }
      }
    }

    .blog-note-button {
      margin-top: rem(24px);
      padding: rem(0 20px 0 20px);
    }
  }

  .touch-download-sample {
    font-size: rem(15px);
    padding: rem(15px 95px);

    &.has-padding-bottom {
      margin-bottom: rem(42px);
    }
  }

  .tag-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: $grey-000;
    border: 1px solid $grey-100;
    padding: rem(33px 24px);
    margin: rem(42px 0);

    @include mq($until: desktop) {
      justify-content: left;
    }

    @include mq($from: mobile, $until: 396px) {
      justify-content: center;
    }

    .blog-note-tag {
      white-space: nowrap;
      max-width: rem(136px);
      width: 100%;

      @include mq($until: desktop) {
        max-width: calc(100% / 6);
      }

      @include mq($until: desktop) {
        max-width: calc(100% / 4);
      }

      @include mq($until: small-tablet) {
        max-width: calc(100% / 3);
      }

      @include mq($from: mobile, $until: 396px) {
        max-width: rem(136px);
      }
    }
  }
}
