@import 'styles/utils/index';

$yellow: #ebaa00;
$yellow-saturate: rgba(235, 170, 0, 0.1);

$light-blue: #497afa;
$light-blue-saturate: rgba(73, 122, 250, 0.1);

$light-green: #5ab758;
$light-green-saturate: rgba(90, 183, 88, 0.1);

$red: #ea434a;
$red-saturate: rgba(234, 67, 74, 0.1);

$blue: #0188d3;
$blue-saturate: rgba(1, 136, 211, 0.1);

$green: #00bb92;
$green-saturate: rgba(0, 187, 146, 0.1);

$purple: #4f52e3;
$purple-saturate: rgba(79, 82, 227, 0.1);

.scores-wrapper {
  display: flex;
  margin: rem(0 auto);
  width: 100%;

  @include mq($until: small-desktop) {
    justify-content: center;
    flex-wrap: wrap;
  }

  .score-item {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: normal;
    text-align: center;
    border-radius: rem(4px);
    padding: rem(10px 12px);
    width: 100%;
    border-width: 1px;
    border-style: solid;

    &:not(:last-child) {
      margin-right: rem(16px);
    }

    @include mq($until: small-desktop) {
      max-width: rem(160px);
      margin-bottom: rem(16px);

      &:nth-child(3n) {
        margin-right: 0;
      }
    }

    @include mq($until: small-tablet) {
      &:nth-child(even) {
        margin-right: 0;
      }

      &:nth-child(odd) {
        margin-right: rem(16px);
      }
    }

    @include mq($until: iphone-x) {
      &:nth-child(odd) {
        margin-right: 0;
      }
    }

    &.score-yellow {
      border-color: $yellow-saturate;
      background: $yellow-saturate;

      p {
        color: $yellow;
      }
    }

    &.score-light-blue {
      border-color: $light-blue-saturate;
      background: $light-blue-saturate;

      p {
        color: $light-blue;
      }
    }

    &.score-red {
      border-color: $red-saturate;
      background: $red-saturate;

      p {
        color: $red;
      }
    }

    &.score-green {
      border-color: $green-saturate;
      background: $green-saturate;

      p {
        color: $green;
      }
    }

    &.score-a,
    &.score-b,
    &.score-c,
    &.score-d,
    &.score-e,
    &.score-n-a {
      width: 50%;
    }

    &.score-a {
      background-color: $white;
      border-color: $score-a;

      p,
      span {
        color: $score-a;
      }
    }

    &.score-b {
      background-color: $white;
      border-color: $score-b;

      p,
      span {
        color: $score-a;
      }
    }

    &.score-c {
      background-color: $white;
      border-color: $score-c;
      color: $score-a;
    }

    &.score-d {
      background-color: $white;
      border-color: $score-d;
      color: $score-a;
    }

    &.score-e {
      background-color: $white;
      border-color: $score-e;
      color: $score-a;
    }

    &.score-n-a {
      background-color: $white;
      border-color: $score-n-a;
      color: $score-n-a;
    }

    p {
      font-size: rem(16px);
      padding-bottom: 0 !important;
    }

    span {
      font-size: rem(12px);
      color: $font-2;
    }
  }
}
