@import 'styles/utils/index';

.category-wrapper {
  h4 {
    font-size: rem(32px);
    line-height: rem(40px);
    font-weight: 700;
    color: $font-4;
    margin-bottom: rem(24px);

    @include mq($until: iphone-11) {
      font-size: rem(24px);
      line-height: rem(28px);
    }
  }
}
