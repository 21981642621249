@import 'styles/utils/index';

.touch-data-wrapper {
  display: flex;
  justify-content: space-between;
  background-color: $grey-100;
  margin-top: rem(16px);
  padding: rem(16px);
  border-radius: 1px;
  align-items: center;

  @include mq($until: small-desktop) {
    margin-bottom: rem(16px);
  }

  @include mq($until: small-tablet) {
    flex-direction: column;
    justify-content: center;
    align-items: center;

    a {
      align-self: center !important;
    }
  }

  .input-wrapper {
    margin: 0;
  }

  .touch-data-detail {
    font-weight: 600;
    font-size: rem(18px);
    line-height: rem(28px);
    color: $font-4;
    margin: 0 !important;
    align-self: center;
    white-space: normal;
    padding-right: rem(16px);

    @include mq($until: small-tablet) {
      padding-right: 0;
      text-align: center !important;
      margin-bottom: rem(16px) !important;
    }

    @include mq($until: iphone-11) {
      align-self: flex-start !important;
      text-align: start !important;
    }
  }

  @include mq($until: iphone-11) {
    a {
      max-width: 100% !important;

      button {
        max-width: 100% !important;
        width: 100%;
      }
    }
  }
}

.touch-tabs {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: rem($layout-base-2);

  .touch-data-wrapper {
    display: flex;
    flex-direction: column;
    max-width: rem(320px);
    width: 100%;
    padding: rem(24px);
    background-color: $grey-000;
    border: 1px solid $grey-100;
    box-sizing: border-box;
    border-radius: 2px;
    text-align: left;
  }
  .block-wrapper {
    h2 {
      font-size: rem(24px);
      line-height: rem(28px);
      color: $font-4;
      margin: 0;
    }
  }
}
