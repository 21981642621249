@import 'styles/utils/index';

.action-modal {
  margin: rem(22px 0 44px);
  min-height: rem(230px);

  p {
    margin: rem(24px 0 18px);
    font-size: rem(36px);
    font-weight: 600;
    line-height: rem(28px);
    color: $main-900;
  }

  span {
    font-size: rem(16px);
    color: $grey-600;
    display: block;
  }

  .action-modal-image {
    font-size: rem(126px) !important;
  }
}
