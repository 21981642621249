@import 'styles/utils/index';

.companies-data {
  max-width: $layout-base-1;
  margin: 0 auto;

  > *:not(:first-child) {
    margin-top: rem(26px);
  }
}

.companies-data-error {
  max-width: $layout-base-1;
  margin: 0 auto;
  text-align: center;

  h2 {
    margin-bottom: rem(20px);
  }

  p {
    font-size: rem(18px);
  }

  button {
    margin-top: rem(20px);
  }
}
