@import 'styles/utils/index';

.input-wrapper {
  position: relative;
  margin: 0 auto;
  max-width: 100%;
  width: 100%;

  &.has-error {
    margin-bottom: rem(25px);

    input,
    textarea {
      border-color: $danger;
      z-index: 1;
    }

    .input-error-label {
      transition: 0.2s;
      bottom: rem(-25px);
    }
  }

  input,
  textarea {
    appearance: none;
    width: 100%;
    background: $white;
    border: 1px solid $grey-200;
    border-radius: rem(2px);
    outline: none;
    padding-left: rem(18px);
    color: $font-3;
    position: static;
    line-height: rem($text-body-height-1);

    &:focus {
      border-color: $main-500;
    }

    &.error {
      border-color: $danger;
    }

    &.disabled {
      border-color: $grey-100;
      cursor: default;
    }

    &.check-input {
      color: $font-3;
    }
  }

  textarea {
    padding-right: rem(18px);
  }

  .input-label {
    position: absolute;
    color: $grey-700;
    pointer-events: none;
    transition: 0.3s all;
    line-height: rem($text-body-height-1);
    text-wrap: nowrap;
    z-index: 1;

    @include mq($until: 320px) {
      left: rem(19px);
    }
  }

  &.input-size-64 {
    input,
    textarea {
      height: rem(64px);
      font-size: rem($text-body-font-1);

      textarea {
        min-height: rem(64px);
      }

      &:focus,
      &.check-input {
        padding-top: rem(18px);

        + span {
          top: rem(5px);
          font-size: rem(14px);
        }
      }

      &.check-input {
        padding-top: rem(25px);
      }
    }

    .input-label {
      top: rem(18.5px);
      left: rem(18px);
      font-size: rem($text-body-font-1);
    }
  }

  &.input-size-48 {
    input,
    textarea {
      height: rem(48px);
      font-size: rem($text-body-font-2);
      padding-left: rem(12px);

      &.check-input {
        + span {
          display: none;
        }
      }
    }

    textarea {
      min-height: rem(48px);
      padding-right: rem(12px);
    }

    .input-label {
      top: rem(10px);
      left: rem(13px);
      font-size: rem($text-body-font-2);
    }
  }

  &.input-size-32 {
    input,
    textarea {
      height: rem(32px);
      font-size: rem(14px);
      padding-left: rem(10px);

      &.check-input {
        + span {
          display: none;
        }
      }
    }

    textarea {
      min-height: rem(32px);
      padding-right: rem(10px);
    }

    .input-label {
      top: rem(2px);
      left: rem(10px);
      font-size: rem(14px);
    }
  }

  textarea {
    height: auto !important;
    transition: padding 0.2s;
    max-width: 100%;

    @include mq($until: desktop) {
      width: 100% !important;
    }
  }

  .input-error-label {
    position: absolute;
    left: 0;
    bottom: 0;
    color: $danger;
    width: 100%;
    text-align: right;
    height: max-content;
  }
}
