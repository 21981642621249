@import 'styles/utils/index';

/** table desktop */
.table {
  max-width: $layout-base-1;
  width: 100%;
  margin: rem(0 auto 20px);
  border: 1px solid $grey-200;
  box-shadow: rem(0) rem(7px) rem(27px) rem(3px) rgba(31, 105, 179, 0.03);
  border-radius: rem(7px);
  font-size: rem(14px);

  .table-header-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: rem(15px) !important;

    .table-title {
      font-size: rem(16px);
    }
  }

  &-wrapper {
    text-transform: uppercase;
    overflow-x: scroll;
    display: flex;
    @include regular-scroll;
    padding-bottom: rem(3px);

    table {
      table-layout: fixed;
      border-collapse: collapse;
      text-align: left;
      width: 100%;

      tr:not(:last-child) {
        border-bottom: 1px solid $grey-200;
      }

      th,
      td {
        padding: rem(15px 0 15px 15px);

        &:last-child {
          padding-right: rem(7.5px);
        }
      }

      thead {
        tr {
          color: $font-4;
          background: #e8f0fe86;

          th {
            font-weight: 400;
            font-size: rem(15px);
            text-wrap: nowrap;
          }
        }

        .table-head-fixed {
          position: sticky;
          left: 0;
          z-index: 3;
          background: #eaeffa;
          padding: 0;

          /** .table-head-fixed-right */
          &-right {
            right: 0;
          }

          > div > div {
            padding: rem(15px 0 15px 15px);

            &:last-child {
              padding-right: rem(7.5px);
            }
          }
        }
      }

      tbody {
        color: $font-3;

        tr:hover {
          background: #e9f4f486;
        }

        .table-row {
          .table-cell-blur {
            filter: blur(2.5px);
            cursor: default;
          }

          &:hover {
            background: #e9f4f486;
          }
        }

        .table-body-fixed {
          position: sticky;
          left: 0;
          z-index: 3;
          background: #fafafa;
          white-space: nowrap;
          padding: 0;

          /** .table-body-fixed-right */
          &-right {
            right: 0;
          }

          > div > div {
            padding: rem(12.5px 15px 12.5px 15px);
          }
        }
      }
    }
  }
}

/** table mobile */
.table-mobile {
  margin: rem(0 auto 20px);

  .table-mobile-title-wrapper {
    padding-top: rem(20px);

    &:not(:last-child) {
      margin-right: rem(10px);
    }

    > * {
      padding-bottom: rem(20px);
    }

    .table-mobile-title-actions {
      display: flex;
      justify-content: center;
    }
  }

  .table-mobile-wrapper {
    &:not(:last-child) {
      margin-bottom: rem(20px);
    }

    .table-mobile-card {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: rem(20px 17.5px);
      border: 1px solid $grey-200;
      border-radius: 4px;

      &:not(:last-child) {
        margin-bottom: rem(20px);
      }

      .table-mobile-card-row {
        width: calc(50% - #{rem(20px)});

        &:not(:last-child) {
          margin-bottom: rem(15px);
        }

        &:first-child {
          width: 100%;
          margin-left: 0;
          margin-bottom: rem(20px);
          padding-bottom: rem(15px);
          border-bottom: 1px solid $grey-200;
        }

        &:last-child {
          width: 100%;
          border-top: 1px solid $grey-200;
          margin-top: rem(20px);
          padding-top: rem(15px);
        }

        .table-mobile-card-item-title {
          color: $font-4;
        }

        .table-mobile-card-item-children {
          color: $font-3;
        }
      }
    }
  }
}
