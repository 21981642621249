@use 'sass:math';

$base-font-size: 16.5px !default;
$rem-baseline: $base-font-size !default;
$rem-fallback: true !default;
$rem-px-only: false !default;

$scrollbar-height: 5px;
$scrollbar-padding: 15px;

// A mixin that converts from px to rem
@function rem-separator($list) {
  @if function-exists('list-separator') ==true {
    @return list-separator($list);
  }
  $test-list: ();
  @each $item in $list {
    $test-list: append($test-list, $item, space);
  }
  @return if($test-list==$list, space, comma);
}

@mixin rem-baseline($zoom: 100%) {
  font-size: $zoom / 16px * $rem-baseline;
}

@function rem-convert($to, $values...) {
  $result: ();
  $separator: rem-separator($values);
  @each $value in $values {
    @if type-of($value) == 'number' and unit($value) == 'rem' and $to== 'px' {
      $result: append($result, $value / 1rem * $rem-baseline, $separator);
    } @else if type-of($value) == 'number' and unit($value) == 'px' and $to== 'rem' {
      $result: append($result, math.div($value, math.div($rem-baseline, 1rem)), $separator);
    } @else if type-of($value) == 'list' {
      $result: append($result, rem-convert($to, $value...), $separator);
    } @else {
      $result: append($result, $value, $separator);
    }
  }
  @return if(length($result) ==1, nth($result, 1), $result);
}

@function rem($values...) {
  @if $rem-px-only {
    @return rem-convert(px, $values...);
  } @else {
    @return rem-convert(rem, $values...);
  }
}

@mixin rem($properties, $values...) {
  @if type-of($properties) == 'map' {
    @each $property in map-keys($properties) {
      @include rem($property, map-get($properties, $property));
    }
  } @else {
    @each $property in $properties {
      @if $rem-fallback or $rem-px-only {
        #{$property}: rem-convert(px, $values...);
      }
      @if not $rem-px-only {
        #{$property}: rem-convert(rem, $values...);
      }
    }
  }
}

@mixin fade-exact-background($name, $color) {
  @keyframes #{$name} {
    from {
      background: rgba($color, 0);
    }
    to {
      background: $color;
    }
  }
}

@keyframes pulsation {
  0% {
    transform: scale(1.2);
  }

  100% {
    transform: inherit;
  }
}

@keyframes fade-right {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}

// scrolls
@mixin slim-scroll {
  scrollbar-color: #809bb2 transparent;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background: #f1f1f1;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #809bb2;
    border-radius: 10px;

    &:hover {
      background-color: #537998;
    }
  }

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
}

@mixin regular-scroll {
  scrollbar-color: #809bb2 transparent;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 7.5px;
    height: 7.5px;
    background: transparent;
    border-radius: 10px;
    transition: 0.3s;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #809bb2;
    border-radius: 10px;

    &:hover {
      background-color: #537998;
    }
  }
}

@mixin hidden-scroll {
  scrollbar-color: transparent transparent;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: transparent;
    border-radius: 0;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 0;
  }

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

@mixin thin-horizontal-scroll {
  &::-webkit-scrollbar {
    height: (($scrollbar-padding * 2) + $scrollbar-height);
  }

  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-thumb {
    border: 16px solid rgba(255, 255, 255, 0);
    background-clip: padding-box;
    border-right-width: 0;
    border-left-width: 0;
  }

  &::-webkit-scrollbar-track {
    background-color: $grey-200;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $font-3;

    &:hover {
      border: $scrollbar-padding solid rgba(255, 255, 255, 0);
      border-right-width: 0;
      border-left-width: 0;
    }

    &:active {
      background-color: $font-4;
    }
  }
}

// skeleton

@mixin skeleton {
  @keyframes skeleton-pulse {
    to {
      background-position: -135% 0%;
    }
  }

  background-image: linear-gradient(-90deg, #efefef 0%, #fcfcfc 20%, #efefef 100%) !important;
  background-size: 400% 400% !important;
  background-position: 0% 0%;
  animation: skeleton-pulse 1s ease-in-out infinite;
  border-radius: rem(8px);

  * {
    opacity: 0;
  }
}

@mixin skeleton-text {
  @include skeleton;

  color: transparent !important;

  > * {
    opacity: 0;
  }
}

@mixin bounce-right {
  @keyframes bounceRight {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateX(0);
    }
    40% {
      transform: translateX(-15px);
    }
    60% {
      transform: translateX(-10px);
    }
  }

  animation: bounceRight 2s infinite;
}

@mixin bounce-left {
  @keyframes bounceLeft {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateX(0);
    }
    40% {
      transform: translateX(15px);
    }
    60% {
      transform: translateX(10px);
    }
  }

  animation: bounceLeft 2s infinite;
}

/** Accessibility */
@mixin focus {
  box-shadow: 0 0 0 4px #ffffff, 0 0 0 6px $main-500;
  outline: none;
  transition-property: box-shadow;
  transition-duration: 200ms;
  transition-timing-function: ease-out;
}

@mixin link-styling {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

@mixin icon-bouncing {
  @keyframes iconBouncing {
    0% {
      transform: inherit;
    }

    50% {
      transform: translateY(-5px);
    }

    100% {
      transform: inherit;
    }
  }
}

@mixin shake-animation($duration: 0.2s) {
  animation: shake $duration linear 1;

  @keyframes shake {
    0% {
      transform: translateX(0);
    }
    25% {
      transform: translateX(-8px);
    }
    50% {
      transform: translateX(8px);
    }
    75% {
      transform: translateX(-8px);
    }
    100% {
      transform: translateX(8px);
    }
  }
}

@mixin delayed-box-shadow-animation($offset-x, $offset-y, $blur, $color, $delay) {
  @keyframes shadow-animation {
    from {
      box-shadow: none; // Start with no shadow
    }
    to {
      box-shadow: $offset-x $offset-y $blur $color; // End with the specified shadow properties
    }
  }

  box-shadow: none; // Start with no shadow
  animation: shadow-animation $delay ease-in-out forwards; // Apply animation with specified delay
}
