$layout-base-1: 1216px;
$layout-base-2: 1008px;
$layout-base-3: 800px;

$text-body-font-1: 18px;
$text-body-height-1: 28px;

$text-body-font-2: 16px;
$text-body-height-2: 24px;

$text-body-height-3: 27.2px;

$font: var(--font-cabin);
