@import 'styles/utils/index';

@keyframes fade-modal {
  0% {
    opacity: 0;
  }

  25% {
    transform: scale(0.9) translate(-50%, -50%);
  }

  100% {
    opacity: 1;
    transform: scale(1) translate(-50%, -50%);
  }
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 9999;
  background-color: $white;
  border-radius: 6px;
  transform-origin: bottom center;
  animation: fade-modal 300ms cubic-bezier(0.25, 0.1, 0.25, 1) 0s 1 normal forwards running;
  max-height: 90%;
  overflow-y: auto;
  width: 100%;

  @include mq($until: tablet) {
    width: 96%;
  }

  &-header {
    display: flex;
    margin-bottom: rem(20px);

    .modal-title {
      margin: 0;
      font-size: rem(28px);
      color: $font-4;
      line-height: 100%;
    }

    .close-button {
      margin-left: auto;
      background-color: transparent;
      border: none;
      cursor: pointer;

      svg {
        font-size: rem(18px);

        path {
          fill: $font-4;
        }
      }
    }
  }

  &-description {
    font-size: rem(14px);
    margin-bottom: rem(26px);
    color: $font-3;

    .modal-tag {
      margin-right: 0;
      text-wrap: initial;
    }
  }

  &-content {
    display: flex;
    justify-content: center;

    form {
      padding: 0;
      border: none;

      h5 {
        margin-top: 0;
      }
    }
  }
}
