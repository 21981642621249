@import 'styles/utils/index';

.wrapper {
  background: $white-secondary;
  border: 1px solid $main-700;
  border-radius: rem(16px);
  display: flex;
  align-items: center;
  height: rem(24px);
  padding: rem(0 8px);
  color: $main-700;
  font-weight: 400;
  font-size: rem(12px);
  margin-left: rem(8px);
  white-space: nowrap;

  @include mq($until: wide) {
    margin-top: rem(10px);
    margin-left: 0;
  }

  @include mq($until: desktop) {
    margin-top: 0;
    margin-left: rem(8px);
  }

  svg {
    margin-right: rem(5px);

    path {
      fill: $main-700;
    }
  }
}
