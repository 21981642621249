@import 'styles/utils/index';

.error-message {
  margin: rem(6px 0 -32px) !important;
  padding-bottom: rem(8px);
  width: 100%;

  &.error-right {
    text-align: right;
  }

  &.error-left {
    text-align: center;
  }

  &.error-left {
    text-align: left;
  }

  .error-title {
    font-size: rem(16px);
    color: $danger;
  }
}
