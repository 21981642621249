@import 'styles/utils/index';

.credit-report {
  position: relative;
  max-width: rem($layout-base-1);
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  @include mq($until: tablet) {
    max-width: 100%;
  }

  &-wrapper {
    display: grid;
    grid-template-columns: 55% 25% 20%;
    max-width: rem(880px);
    width: 100%;
    margin: rem(28px auto 100px);

    @include mq($from: desktop) {
      > *:not(:last-child),
      > *:not(:last-child) input,
      > *:not(:last-child) :global(.rc-select) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      > *:not(:first-child),
      > *:not(:first-child) input,
      > *:not(:first-child) :global(.rc-select) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    @include mq($until: small-desktop) {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(3, 1fr);
      grid-row-gap: rem(15px);
    }

    @include mq($until: small-tablet) {
      grid-template-rows: auto;
    }

    .rc-select-selector {
      border: none !important;
    }

    .select-credit-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-family: $font;
      font-weight: 400;
      width: 100%;

      .input-alert {
        transition: all 2s ease-in-out;
        color: $danger;
        display: block;
        text-align: right;
        width: 100%;
      }

      :global(.select) {
        display: flex;
        width: 100%;
      }
    }

    &.advanced {
      display: grid;
      grid-template-columns: 40% 1fr 16%;
      max-width: 100%;

      .select-credit-wrapper {
        flex-direction: row;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;

        @include mq($until: desktop) {
          grid-column-gap: rem(15px);
        }

        @include mq($until: small-tablet) {
          grid-row-gap: rem(15px);
          grid-template-columns: 1fr;
        }
      }

      .input-error-label {
        left: 50%;
      }

      &.credit-report-wrapper {
        @include mq($until: desktop) {
          grid-template-columns: 1fr;
          grid-row-gap: rem(15px);
        }
      }
    }

    button {
      width: 100%;

      & + div:first-child {
        margin-top: rem(12px) !important;
      }
    }

    > div {
      input {
        position: relative;

        /** should not have rem */
        right: -1px;

        &:focus {
          z-index: 1;
        }

        @include mq($until: small-tablet) {
          max-width: 100%;
          width: 100%;
        }
      }
    }
  }

  &.credit-report-size-48,
  &.credit-report-size-32 {
    max-width: 100%;

    button {
      padding: 0;
    }
  }
}
