@import 'styles/utils/index';

.progress {
  overflow: hidden;
  left: 0;
  top: 0;
  width: 1%;
  height: rem(3px);
  background-color: $main-500;
  max-width: 100%;
  z-index: 9999;

  @include mq($until: small-tablet) {
    height: rem(2px);
  }
}
