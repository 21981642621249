@import 'styles/utils/index';

.form-request-block {
  display: flex;
  align-items: center;
  max-width: $layout-base-1;
  margin: rem(80px auto 90px);
  width: 100%;

  & > div:first-child {
    max-width: rem(592px);
    margin-right: rem(32px);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center top;
    height: rem(430px);

    &.intersected {
      background-image: url('/storage/svg/decoration/free-trial-decoration.svg');
    }

    @include mq($until: iphone-11) {
      height: rem(300px);
    }
  }

  h1 {
    font-weight: 700;
    font-size: rem(56px);
    line-height: rem(64px);
    color: $font-4;
    margin: 0;
  }

  p {
    font-size: rem(24px);
    line-height: rem(32px);
    color: $font-3;
    margin: 0;
    margin-top: rem(20px);
  }

  @include mq($until: wide) {
    padding: rem(0 15px);
    justify-content: center;
  }

  @include mq($until: desktop) {
    flex-direction: column;

    h1 {
      text-align: center;
    }

    p {
      text-align: center;
      margin-bottom: rem(40px);
    }
  }

  @include mq($until: iphone-11) {
    h1 {
      text-align: left;
    }

    p {
      text-align: left;
    }
  }
}
