@import 'styles/utils/index';

.collapse-wrapper {
  display: flex;
  flex-direction: column;

  &:not(:first-child) {
    margin-top: rem(13px);
  }

  .first-block-wrapper {
    max-width: rem(696px);
    width: 100%;
    margin: 0 auto;

    &.collapsed {
      margin-bottom: rem(24px);
    }

    &.loading .title-wrapper h2 {
      color: $grey-300 !important;
    }

    .title-wrapper {
      display: flex;
      cursor: pointer;

      h2 {
        margin: rem(0 auto 0 0);
        font-size: rem(24px);
        line-height: rem(28px);
        color: $main-500 !important;
        padding-right: rem(15px);
      }
    }

    hr {
      margin: rem(13px 0 0);
      width: 100%;
      border: 0;
      border-bottom: 1px solid $grey-200;
    }
  }
}
