@import 'styles/utils/index';

.scroll-wrapper {
  position: fixed;
  bottom: rem(32px);
  right: rem(15px);
  left: rem(15px);

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @include mq($from: desktop) {
    display: none;
  }

  &-animate-up {
    @extend .scroll-wrapper;
    animation: slide-up 700ms ease-out forwards;
  }

  &-animate-down {
    @extend .scroll-wrapper;
    animation: slide-down 700ms ease-out forwards;
  }
}

@keyframes slide-up {
  0% {
    transform: translate3d(0, rem(140px), 0);
  }
  100% {
    transform: translate3d(0, rem(0px), 0);
  }
}

@keyframes slide-down {
  0% {
    transform: translate3d(0, rem(0px), 0);
  }
  100% {
    transform: translate3d(0, rem(140px), 0);
  }
}

.button {
  width: 100%;
}
