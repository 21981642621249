@import 'styles/utils/index';

.agent-template-skeleton-wrapper {
  margin: rem(68px auto 0);

  .agent-template-skeleton-title {
    color: transparent;
    width: max-content;
    margin: 0 auto rem(66px);
    font-size: rem(36px);
    @include skeleton;
  }

  .agent-template-skeleton-item {
    width: 100%;
    max-width: rem(800px);
    height: rem(66px);
    margin: rem(0 auto 68px);
    @include skeleton;
  }

  .agent-template-skeleton-link {
    color: transparent;
    font-size: rem(18px);
    display: block;
    margin: rem(0 auto 32px);
    width: max-content;
    @include skeleton;
  }

  .agent-template-skeleton-cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: rem(32px);

    .agent-template-skeleton-card {
      width: 100%;
      max-width: rem(390px);
      height: rem(144px);
      padding: rem(24px);
      border-radius: rem(16px);
      @include skeleton;
    }
  }
}
