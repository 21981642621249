@import 'styles/utils/index';

.our-data {
  padding: 0 rem(15px);
  position: relative;

  .arrows {
    text-align: center;

    svg {
      font-size: rem(22px);
      height: rem(30px);
      fill: none;
    }

    .icon-orange {
      path {
        stroke: #ffb84f;
      }
    }

    .icon-green {
      path {
        stroke: #07e796;
      }
    }

    .icons {
      text-align: center;
    }
  }

  .request-demo__wrapper {
    margin-top: rem(192px);

    @include mq($until: iphone-11) {
      margin-top: rem(56px);
    }
  }

  .info-block h5 {
    color: $main-500;
    font-size: rem(56px);
    line-height: rem(68px);
    font-weight: 700;

    @include mq($until: tablet) {
      font-size: rem(28px);
    }
  }

  .arrows-title {
    margin: rem(50px) 0;
  }

  .first-arrow-block {
    margin: 0 auto;
    text-align: center;
    max-width: rem(864px);
    width: 100%;

    h5 {
      padding-top: rem(50px);
      padding-bottom: rem(50px);
      color: $font-3;
      font-size: rem(20px);
      font-weight: 500;
      line-height: rem($text-body-height-3);
      margin: 0;
    }

    .ways {
      display: flex;
      justify-content: space-between;
      padding-top: rem(50px);
      padding-bottom: rem(50px);

      &-arrows-information {
        display: flex;
        justify-content: space-between;

        svg {
          font-size: rem(22px);
          height: rem(30px);
          fill: none;
        }

        > svg:nth-child(1) {
          margin-left: rem(110px);
        }

        > svg:nth-child(3) {
          margin-right: rem(110px);
        }
      }

      &-item:nth-child(2) {
        @include mq($until: tablet) {
          margin-left: rem(5px);
          margin-right: rem(5px);
        }
      }

      &-item {
        display: flex;
        align-items: center;
        box-sizing: border-box;
        border-radius: rem(2px);
        border: 2px solid $main-500;
        max-width: rem(240px);
        width: 100%;
        // max-height: rem(48px);
        padding: rem(15px) rem(15px);

        @include mq($until: small-tablet) {
          max-height: rem(60px);
        }

        span {
          color: $font-4;
          font-weight: 500;
          margin: 0 auto;
          font-size: rem($text-body-font-2);
          line-height: rem($text-body-height-2);

          @include mq($until: small-tablet) {
            font-size: rem(14px);
          }
        }
      }

      &-information {
        display: flex;
        justify-content: space-between;
        padding-top: rem(50px);

        @include mq($until: tablet) {
          display: block;
          margin: 0 auto;
        }

        &-item {
          max-width: rem(240px);
          width: 100%;
          height: 100%;
          border-radius: rem(2px);
          padding: rem(20px) rem(0px);
          background-image: repeating-linear-gradient(
              -25deg,
              $main-500,
              $main-500 20px,
              transparent 20px,
              transparent 31px,
              $main-500 31px
            ),
            repeating-linear-gradient(
              65deg,
              $main-500,
              $main-500 20px,
              transparent 20px,
              transparent 31px,
              $main-500 31px
            ),
            repeating-linear-gradient(
              155deg,
              $main-500,
              $main-500 20px,
              transparent 20px,
              transparent 31px,
              $main-500 31px
            ),
            repeating-linear-gradient(
              245deg,
              $main-500,
              $main-500 20px,
              transparent 20px,
              transparent 31px,
              $main-500 31px
            );
          background-size: 2px 100%, 100% 2px, 2px 100%, 100% 2px;
          background-position: 0 0, 0 0, 100% 0, 0 100%;
          background-repeat: no-repeat;

          @include mq($until: tablet) {
            margin: rem(30px) auto;
          }

          ul {
            li {
              font-size: rem(14px);
              line-height: rem($text-body-height-2);
              font-weight: 400;
              color: $font-4;
            }
          }
        }
      }
    }
  }

  .second-arrow-block {
    margin: 0 auto;
    text-align: center;
    width: 100%;
    max-width: rem($layout-base-2);

    &-title {
      display: flex;
      justify-content: space-between;
      padding-top: rem(150px);

      @include mq($until: wide) {
        display: block;

        > div:nth-child(2) {
          display: none;
        }
      }
    }

    .arrows-to-title {
      display: flex;
      justify-content: space-between;

      @include mq($until: wide) {
        display: block;
      }

      &-text:nth-child(2) {
        @include mq($until: wide) {
          display: none;
        }
      }

      .arrows-to-title-text {
        margin-left: rem(75px);

        @include mq($until: wide) {
          margin-left: rem(0px);
        }

        h5 {
          font-weight: 500;
          font-size: rem(20px);
          line-height: rem(27.2px);
          color: $font-3;
          margin: 0;
          padding-top: rem(50px);
          padding-bottom: rem(50px);
        }
      }

      &-text:nth-child(2) {
        margin-left: rem(0px);
        margin-right: rem(75px);
      }
    }
  }

  .second-arrow-block-ways {
    display: flex;
    justify-content: space-between;
    max-width: rem(1176px);
    width: 100%;
    margin: 0 auto;

    @include mq($until: wide) {
      display: block;
    }

    .left-side {
      text-align: center;

      .left-side-ways {
        display: flex;
        justify-content: space-between;
        width: 100%;
        max-width: rem(552px);
        padding-top: rem(50px);
        padding-bottom: rem(50px);

        @include mq($until: wide) {
          margin: 0 auto;
        }

        &-item:nth-child(1) {
          margin-right: rem(62px);
        }

        &-item {
          display: flex;
          align-items: center;
          box-sizing: border-box;
          border-radius: rem(2px);
          border: 2px solid #ffb84f;
          width: rem(240px);
          // max-height: rem(48px);
          padding: rem(15px) rem(0px);

          span {
            color: $font-4;
            font-weight: 500;
            margin: 0 auto;
            font-size: rem($text-body-font-2);
            line-height: rem($text-body-height-2);
          }
        }
      }

      .left-side-ways-arrows-information {
        display: flex;
        justify-content: space-between;
        padding-bottom: rem(50px);

        svg {
          font-size: rem(22px);
          height: rem(30px);
          fill: none;
        }

        svg:nth-child(1) {
          margin-left: rem(110px);

          path {
            stroke: #ffb84f;
          }

          @include mq($until: wide) {
            margin: 0 auto;
          }
        }

        svg:nth-child(2) {
          margin-right: rem(110px);

          path {
            stroke: #ffb84f;
          }

          @include mq($until: wide) {
            display: none;
          }
        }

        .icons {
          svg {
            path {
              stroke: #ffb84f;
            }
          }
        }
      }

      .left-information {
        display: flex;
        justify-content: space-between;
        width: 100%;
        max-width: rem(552px);

        @include mq($until: wide) {
          margin: 0 auto;
        }

        &-item:nth-child(1) {
          @include mq($until: small-tablet) {
            margin-right: rem(5px);
          }
        }

        &-item:nth-child(2) {
          @include mq($until: small-tablet) {
            margin-left: rem(5px);
          }
        }

        &-item {
          max-width: rem(240px);
          width: 100%;
          height: 100%;
          border-radius: rem(2px);
          padding: rem(20px) rem(0px);
          background-image: repeating-linear-gradient(
              -25deg,
              #ffb84f,
              #ffb84f 20px,
              transparent 20px,
              transparent 31px,
              #ffb84f 31px
            ),
            repeating-linear-gradient(65deg, #ffb84f, #ffb84f 20px, transparent 20px, transparent 31px, #ffb84f 31px),
            repeating-linear-gradient(155deg, #ffb84f, #ffb84f 20px, transparent 20px, transparent 31px, #ffb84f 31px),
            repeating-linear-gradient(245deg, #ffb84f, #ffb84f 20px, transparent 20px, transparent 31px, #ffb84f 31px);
          background-size: 2px 100%, 100% 2px, 2px 100%, 100% 2px;
          background-position: 0 0, 0 0, 100% 0, 0 100%;
          background-repeat: no-repeat;

          ul {
            li {
              font-size: rem(14px);
              line-height: rem($text-body-height-2);
              font-weight: 400;
              color: $font-4;
            }
          }
        }
      }
    }

    .right-side {
      text-align: center;

      .color-title-mq-green {
        display: none;

        svg {
          font-size: rem(22px);
          height: rem(30px);
          fill: none;

          path {
            stroke: #07e796;
          }
        }

        @include mq($until: wide) {
          display: block;
        }
      }

      .right-side-ways {
        display: flex;
        justify-content: space-between;
        width: 100%;
        max-width: rem(552px);
        padding-top: rem(50px);
        padding-bottom: rem(50px);

        @include mq($until: wide) {
          margin: 0 auto;
        }

        &-item:nth-child(1) {
          margin-right: rem(62px);
        }

        &-item {
          display: flex;
          align-items: center;
          box-sizing: border-box;
          border-radius: rem(2px);
          border: 2px solid #07e796;
          width: rem(240px);
          // max-height: rem(48px);
          padding: rem(15px) rem(0px);

          span {
            color: $font-4;
            font-weight: 500;
            margin: 0 auto;
            font-size: rem($text-body-font-2);
            line-height: rem($text-body-height-2);
          }
        }
      }

      .right-side-ways-arrows-information {
        display: flex;
        justify-content: space-between;
        padding-bottom: rem(50px);

        svg:nth-child(1) {
          margin-left: rem(110px);
          font-size: rem(22px);
          height: rem(30px);
          fill: none;

          path {
            stroke: #07e796;
          }
          @include mq($until: wide) {
            margin: 0 auto;
          }
        }

        svg:nth-child(2) {
          margin-right: rem(110px);
          font-size: rem(22px);
          height: rem(30px);
          fill: none;

          path {
            stroke: #07e796;
          }
          @include mq($until: wide) {
            display: none;
          }
        }

        .icons {
          svg {
            path {
              stroke: #07e796;
            }
          }
        }
      }

      .right-information {
        display: flex;
        justify-content: space-between;
        width: 100%;
        max-width: rem(552px);

        @include mq($until: wide) {
          margin: 0 auto;
        }

        &-item:nth-child(1) {
          @include mq($until: small-tablet) {
            margin-right: rem(5px);
          }
        }

        &-item:nth-child(2) {
          @include mq($until: small-tablet) {
            margin-left: rem(5px);
          }
        }

        &-item {
          max-width: rem(240px);
          width: 100%;
          height: 100%;
          border-radius: rem(2px);
          padding: rem(20px) rem(0px);
          background-image: repeating-linear-gradient(
              -25deg,
              #07e796,
              #07e796 20px,
              transparent 20px,
              transparent 31px,
              #07e796 31px
            ),
            repeating-linear-gradient(65deg, #07e796, #07e796 20px, transparent 20px, transparent 31px, #07e796 31px),
            repeating-linear-gradient(155deg, #07e796, #07e796 20px, transparent 20px, transparent 31px, #07e796 31px),
            repeating-linear-gradient(245deg, #07e796, #07e796 20px, transparent 20px, transparent 31px, #07e796 31px);
          background-size: 2px 100%, 100% 2px, 2px 100%, 100% 2px;
          background-position: 0 0, 0 0, 100% 0, 0 100%;
          background-repeat: no-repeat;

          ul {
            li {
              font-size: rem(14px);
              line-height: rem($text-body-height-2);
              font-weight: 400;
              color: $font-4;
            }
          }
        }
      }
    }
  }

  .partnership-title {
    margin-top: rem(200px);
    margin-bottom: rem(50px);
  }

  .public-title {
    margin-top: rem(200px);

    @include mq($until: iphone-11) {
      margin-top: rem(112px);
    }
    .icons {
      padding-top: rem(50px);

      svg {
        font-size: rem(22px);
        height: rem(30px);
        fill: none;

        path {
          stroke: #ffb84f;
        }
      }
    }
  }

  .first-arrow-block-orange {
    margin: 0 auto;
    text-align: center;
    max-width: 864px;
    width: 100%;

    .arrows {
      svg {
        font-size: rem(22px);
        height: rem(30px);
        fill: none;

        path {
          stroke: #ffb84f;
        }
      }
    }

    h5 {
      padding-top: rem(50px);
      padding-bottom: rem(50px);
      color: $font-3;
      font-size: rem(20px);
      font-weight: 500;
      line-height: rem($text-body-height-3);
      margin: 0;
    }

    .ways {
      display: flex;
      justify-content: space-between;
      padding-top: rem(50px);
      padding-bottom: rem(50px);

      &-item {
        @include mq($until: small-tablet) {
          max-height: rem(60px!important);
        }

        span {
          @include mq($until: small-tablet) {
            font-size: rem(14px!important);
          }
        }
      }

      &-item:nth-child(2) {
        @include mq($until: tablet) {
          margin-left: 5px;
          margin-right: 5px;
        }
      }

      &-arrows-information {
        display: flex;
        justify-content: space-between;

        > svg:nth-child(1) {
          margin-left: rem(110px);
        }

        > svg:nth-child(3) {
          margin-right: rem(110px);
        }

        svg {
          font-size: rem(22px);
          height: rem(30px);
          fill: none;

          path {
            stroke: #ffb84f;
          }
        }
      }

      &-item {
        display: flex;
        align-items: center;
        box-sizing: border-box;
        border-radius: rem(2px);
        border: 2px solid #ffb84f;
        max-width: rem(240px);
        width: 100%;
        // max-height: rem(48px);
        padding: rem(15px) rem(15px);

        span {
          color: $font-4;
          font-weight: 500;
          margin: 0 auto;
          font-size: rem($text-body-font-2);
          line-height: rem($text-body-height-2);
        }
      }

      &-information {
        display: flex;
        justify-content: space-between;
        padding-top: rem(50px);

        @include mq($until: tablet) {
          display: block;
          margin: 0 auto;
        }

        &-item {
          max-width: rem(240px);
          width: 100%;
          height: 100%;
          border-radius: rem(2px);
          padding: rem(20px) rem(0px);
          background-image: repeating-linear-gradient(
              -25deg,
              #ffb84f,
              #ffb84f 20px,
              transparent 20px,
              transparent 31px,
              #ffb84f 31px
            ),
            repeating-linear-gradient(65deg, #ffb84f, #ffb84f 20px, transparent 20px, transparent 31px, #ffb84f 31px),
            repeating-linear-gradient(155deg, #ffb84f, #ffb84f 20px, transparent 20px, transparent 31px, #ffb84f 31px),
            repeating-linear-gradient(245deg, #ffb84f, #ffb84f 20px, transparent 20px, transparent 31px, #ffb84f 31px);
          background-size: 2px 100%, 100% 2px, 2px 100%, 100% 2px;
          background-position: 0 0, 0 0, 100% 0, 0 100%;
          background-repeat: no-repeat;

          @include mq($until: tablet) {
            margin: rem(30px) auto;
          }

          ul {
            li {
              font-size: rem(14px);
              line-height: rem($text-body-height-2);
              font-weight: 400;
              color: $font-4;
            }
          }
        }
      }
    }
  }

  .data-accuracy-title {
    padding-top: rem(200px);
    max-width: rem($layout-base-2);
    width: 100%;
    text-align: center;
    margin: 0 auto;

    @include mq($until: iphone-11) {
      padding-top: rem(56px);
      text-align: start;
    }

    h2 {
      font-weight: 700;
      color: $font-4;
      margin: 0;

      @include mq($until: small-tablet) {
        font-size: rem(35px);
      }
    }

    p {
      font-weight: 500;
      font-size: rem(24px);
      line-height: rem($text-body-height-3);
      color: $font-3;
      padding-bottom: rem(40px);

      @include mq($until: small-tablet) {
        font-size: rem($text-body-font-1);
        line-height: rem($text-body-height-1);
      }
    }
  }

  .example-blocks {
    max-width: rem($layout-base-2);
    width: 100%;
    margin: 0 auto;
    text-align: center;
    padding-bottom: rem(100px);

    @include mq($until: iphone-11) {
      padding-bottom: rem(56px);
    }

    h4 {
      font-weight: 700;
      color: $font-4;
      padding-bottom: rem(30px);

      @include mq($until: small-tablet) {
        font-size: rem(28px);
      }
    }

    &-item {
      display: flex;
      justify-content: space-between;

      @include mq($until: small-desktop) {
        display: block;
      }

      .block-1 {
        width: rem(280px);
        height: rem(280px);
        background-color: #07e796;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        @include mq($until: small-desktop) {
          margin: rem(15px) auto;
          max-width: 500px;
          width: 100%;
        }

        h2 {
          color: $white;
          font-size: rem(48px);
          line-height: rem(58px);
          font-weight: 700;
          margin: 0;
          padding-top: rem(90px);
          padding-bottom: rem(10px);

          @include mq($until: small-tablet) {
            font-size: rem(35px);
            line-height: rem(32px);
            text-align: center;
          }
        }

        p {
          color: $white;
          opacity: 0.8;
          line-height: rem($text-body-height-3);
          font-size: rem(24px);
          font-weight: 500;
          margin: 0;
        }
      }

      .block-2 {
        width: rem(280px);
        height: rem(280px);
        background-color: $danger;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        @include mq($until: small-desktop) {
          margin: rem(15px) auto;
          max-width: 500px;
          width: 100%;
        }

        h2 {
          color: $white;
          font-weight: 700;
          margin: 0;
          padding-top: rem(90px);
          padding-bottom: rem(10px);

          @include mq($until: small-tablet) {
            font-size: rem(35px);
            line-height: rem(32px);
            text-align: center;
          }
        }

        p {
          color: $white;
          opacity: 0.8;
          line-height: rem($text-body-height-3);
          font-size: rem(24px);
          font-weight: 500;
          margin: 0;
        }
      }

      .block-3 {
        width: rem(280px);
        height: rem(280px);
        background-color: #ffb84f;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        padding: rem(0px) rem(50px);

        @include mq($until: small-desktop) {
          margin: rem(15px) auto;
          max-width: 500px;
          width: 100%;
        }

        h2 {
          color: $white;
          font-weight: 700;
          margin: 0;
          padding-top: rem(90px);
          padding-bottom: rem(10px);

          @include mq($until: small-tablet) {
            font-size: rem(35px);
            line-height: rem(32px);
            text-align: center;
          }
        }

        p {
          color: $white;
          opacity: 0.8;
          line-height: rem($text-body-height-3);
          font-size: rem(24px);
          font-weight: 500;
          margin: 0;
        }
      }
    }
  }

  .data-update-title {
    padding-top: rem(70px);

    .arrows {
      padding-top: rem(50px);
      padding-bottom: rem(50px);

      .icons {
        svg {
          path {
            stroke: $main-500;
          }
        }
      }
    }
  }

  .data-update-content {
    max-width: rem(864px);
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    @include mq($until: small-tablet) {
      display: block;
    }

    &-title:nth-child(2) {
      @include mq($until: small-desktop) {
        margin-left: rem(5px);
        margin-right: rem(5px);
      }

      @include mq($until: small-tablet) {
        margin: 0 auto;
      }
    }

    &-title:nth-child(1) {
      max-width: rem(240px);
      width: 100%;

      @include mq($until: small-tablet) {
        margin: 0 auto;
      }

      .arrows {
        padding-top: rem(50px);

        @include mq($until: small-tablet) {
          padding-bottom: rem(50px);
        }
        .icons {
          svg {
            path {
              stroke: #ffb84f;
            }
          }
        }
      }

      .data-update-content-item {
        display: flex;
        align-items: center;
        box-sizing: border-box;
        border-radius: rem(2px);
        border: 2px solid #ffb84f;
        max-width: rem(240px);
        width: 100%;
        // max-height: rem(48px);
        padding: rem(15px) rem(35px);
        text-align: center;

        @include mq($until: small-desktop) {
          max-height: rem(70px);
          padding: rem(25px) rem(35px);
        }

        span {
          color: $font-4;
          font-weight: 500;
          margin: 0 auto;
          font-size: rem($text-body-font-2);
          line-height: rem($text-body-height-2);

          @include mq($until: tablet) {
            font-size: rem(14px);
          }
        }
      }
    }

    &-title:nth-child(2) {
      max-width: rem(240px);
      width: 100%;

      .arrows {
        padding-top: rem(50px);

        @include mq($until: small-tablet) {
          padding-bottom: rem(50px);
        }

        .icons {
          svg {
            path {
              stroke: #07e796;
            }
          }
        }
      }

      .data-update-content-item {
        display: flex;
        align-items: center;
        box-sizing: border-box;
        border-radius: rem(2px);
        border: 2px solid #07e796;
        max-width: rem(240px);
        width: 100%;
        // max-height: rem(48px);
        padding: rem(15px) rem(50px);
        text-align: center;

        @include mq($until: small-desktop) {
          max-height: rem(70px);
        }

        span {
          color: $font-4;
          font-weight: 500;
          margin: 0 auto;
          font-size: rem($text-body-font-2);
          line-height: rem($text-body-height-2);

          @include mq($until: tablet) {
            font-size: rem(14px);
          }
        }
      }
    }

    &-title:nth-child(3) {
      max-width: rem(240px);
      width: 100%;

      @include mq($until: small-tablet) {
        margin: 0 auto;
      }

      .arrows {
        padding-top: rem(50px);

        .icons {
          svg {
            path {
              stroke: $main-500;
            }
          }
        }
      }

      .data-update-content-item {
        display: flex;
        align-items: center;
        box-sizing: border-box;
        border-radius: rem(2px);
        border: 2px solid $main-500;
        max-width: rem(240px);
        width: 100%;
        // max-height: rem(48px);
        padding: rem(15px) rem(35px);
        text-align: center;

        @include mq($until: small-desktop) {
          max-height: rem(70px);
        }

        span {
          color: $font-4;
          font-weight: 500;
          margin: 0 auto;
          font-size: rem($text-body-font-2);
          line-height: rem($text-body-height-2);

          @include mq($until: tablet) {
            font-size: rem(14px);
          }
        }
      }
    }
  }

  .data-update-dashed {
    max-width: rem(864px);
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding-top: rem(50px);

    @include mq($until: small-tablet) {
      display: none;
    }

    .data-update-content-title {
      .icon-orange {
        svg {
          font-size: rem(22px);
          height: rem(30px);
          fill: none;

          path {
            stroke: #ffb84f;
          }
        }
      }

      .data-update-content-item-1 {
        display: flex;
        align-items: center;
        box-sizing: border-box;
        border-radius: rem(2px);
        max-width: rem(240px);
        width: 100%;
        // max-height: rem(48px);
        padding: rem(15px) rem(35px);
        text-align: center;
        background-image: repeating-linear-gradient(
            -25deg,
            #ffb84f,
            #ffb84f 20px,
            transparent 20px,
            transparent 31px,
            #ffb84f 31px
          ),
          repeating-linear-gradient(65deg, #ffb84f, #ffb84f 20px, transparent 20px, transparent 31px, #ffb84f 31px),
          repeating-linear-gradient(155deg, #ffb84f, #ffb84f 20px, transparent 20px, transparent 31px, #ffb84f 31px),
          repeating-linear-gradient(245deg, #ffb84f, #ffb84f 20px, transparent 20px, transparent 31px, #ffb84f 31px);
        background-size: 2px 100%, 100% 2px, 2px 100%, 100% 2px;
        background-position: 0 0, 0 0, 100% 0, 0 100%;
        background-repeat: no-repeat;

        span {
          margin: 0 auto;
        }
      }

      .data-update-content-item-2 {
        display: flex;
        align-items: center;
        box-sizing: border-box;
        border-radius: rem(2px);
        max-width: rem(240px);
        width: 100%;
        // max-height: rem(48px);
        padding: rem(15px) rem(35px);
        text-align: center;
        background-image: repeating-linear-gradient(
            -25deg,
            #07e796,
            #07e796 20px,
            transparent 20px,
            transparent 31px,
            #07e796 31px
          ),
          repeating-linear-gradient(65deg, #07e796, #07e796 20px, transparent 20px, transparent 31px, #07e796 31px),
          repeating-linear-gradient(155deg, #07e796, #07e796 20px, transparent 20px, transparent 31px, #07e796 31px),
          repeating-linear-gradient(245deg, #07e796, #07e796 20px, transparent 20px, transparent 31px, #07e796 31px);
        background-size: 2px 100%, 100% 2px, 2px 100%, 100% 2px;
        background-position: 0 0, 0 0, 100% 0, 0 100%;
        background-repeat: no-repeat;

        span {
          margin: 0 auto;
        }
      }

      .data-update-content-item-3 {
        display: flex;
        align-items: center;
        box-sizing: border-box;
        border-radius: rem(2px);
        max-width: rem(240px);
        width: 100%;
        // max-height: rem(48px);
        padding: rem(15px) rem(35px);
        text-align: center;
        background-image: repeating-linear-gradient(
            -25deg,
            $main-500,
            $main-500 20px,
            transparent 20px,
            transparent 31px,
            $main-500 31px
          ),
          repeating-linear-gradient(
            65deg,
            $main-500,
            $main-500 20px,
            transparent 20px,
            transparent 31px,
            $main-500 31px
          ),
          repeating-linear-gradient(
            155deg,
            $main-500,
            $main-500 20px,
            transparent 20px,
            transparent 31px,
            $main-500 31px
          ),
          repeating-linear-gradient(
            245deg,
            $main-500,
            $main-500 20px,
            transparent 20px,
            transparent 31px,
            $main-500 31px
          );
        background-size: 2px 100%, 100% 2px, 2px 100%, 100% 2px;
        background-position: 0 0, 0 0, 100% 0, 0 100%;
        background-repeat: no-repeat;

        span {
          margin: 0 auto;
        }
      }
    }
  }

  .data-update-title-last {
    max-width: rem(552px);
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding-top: rem(100px);

    @include mq($until: small-tablet) {
      display: block;
    }

    .data-update-content-title-last {
      max-width: rem(240px);
      width: 100%;

      @include mq($until: small-tablet) {
        margin: 0 auto;
      }

      .arrows {
        padding-top: rem(50px);
      }

      .data-update-content-item-last-1 {
        display: flex;
        align-items: center;
        box-sizing: border-box;
        border-radius: rem(2px);
        border: 2px solid $main-500;
        max-width: rem(240px);
        width: 100%;
        // max-height: rem(48px);
        padding: rem(15px) rem(35px);
        text-align: center;

        span {
          color: $font-4;
          font-weight: 500;
          margin: 0 auto;
          font-size: rem($text-body-font-2);
          line-height: rem($text-body-height-2);
        }
      }

      .data-update-content-item-last-2 {
        display: flex;
        align-items: center;
        box-sizing: border-box;
        border-radius: rem(2px);
        border: 2px solid #ffb84f;
        max-width: rem(240px);
        width: 100%;
        // max-height: rem(48px);
        padding: rem(15px) rem(35px);
        text-align: center;

        @include mq($until: small-tablet) {
          display: none;
        }

        span {
          color: $font-4;
          font-weight: 500;
          margin: 0 auto;
          font-size: rem($text-body-font-2);
          line-height: rem($text-body-height-2);
        }
      }
    }

    .data-update-content-title-last:nth-child(2) {
      .arrows {
        @include mq($until: small-tablet) {
          display: none;
        }
        .icons {
          svg {
            path {
              stroke: #ffb84f;
            }
          }
        }
      }
    }
  }

  .data-update-title-last-dashed {
    max-width: rem(552px);
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding-top: rem(50px);

    @include mq($until: small-tablet) {
      display: block;
    }

    .data-update-content-title-last-dashed {
      max-width: rem(240px);
      width: 100%;

      @include mq($until: small-tablet) {
        margin: 0 auto;
      }

      .arrows {
        padding-top: rem(50px);
      }

      .arrows:nth-child(2) {
        .icons {
          svg {
            path {
              stroke: #ffb84f;
            }
          }
        }
      }

      .data-update-content-item-last-dashed-1 {
        display: flex;
        align-items: center;
        box-sizing: border-box;
        border-radius: rem(2px);
        background-image: repeating-linear-gradient(
            -25deg,
            $main-500,
            $main-500 20px,
            transparent 20px,
            transparent 31px,
            $main-500 31px
          ),
          repeating-linear-gradient(
            65deg,
            $main-500,
            $main-500 20px,
            transparent 20px,
            transparent 31px,
            $main-500 31px
          ),
          repeating-linear-gradient(
            155deg,
            $main-500,
            $main-500 20px,
            transparent 20px,
            transparent 31px,
            $main-500 31px
          ),
          repeating-linear-gradient(
            245deg,
            $main-500,
            $main-500 20px,
            transparent 20px,
            transparent 31px,
            $main-500 31px
          );
        background-size: 2px 100%, 100% 2px, 2px 100%, 100% 2px;
        background-position: 0 0, 0 0, 100% 0, 0 100%;
        background-repeat: no-repeat;
        max-width: rem(240px);
        width: 100%;
        // max-height: rem(48px);
        padding: rem(15px) rem(35px);
        text-align: center;

        @include mq($until: small-tablet) {
          margin-bottom: rem(50px);
        }

        span {
          color: $font-4;
          font-weight: 500;
          margin: 0 auto;
          font-size: rem($text-body-font-2);
          line-height: rem($text-body-height-2);
        }
      }

      .data-update-content-item-last-dashed-2 {
        display: flex;
        align-items: center;
        box-sizing: border-box;
        border-radius: rem(2px);
        background-image: repeating-linear-gradient(
            -25deg,
            #ffb84f,
            #ffb84f 20px,
            transparent 20px,
            transparent 31px,
            #ffb84f 31px
          ),
          repeating-linear-gradient(65deg, #ffb84f, #ffb84f 20px, transparent 20px, transparent 31px, #ffb84f 31px),
          repeating-linear-gradient(155deg, #ffb84f, #ffb84f 20px, transparent 20px, transparent 31px, #ffb84f 31px),
          repeating-linear-gradient(245deg, #ffb84f, #ffb84f 20px, transparent 20px, transparent 31px, #ffb84f 31px);
        background-size: 2px 100%, 100% 2px, 2px 100%, 100% 2px;
        background-position: 0 0, 0 0, 100% 0, 0 100%;
        background-repeat: no-repeat;
        max-width: rem(240px);
        width: 100%;
        // max-height: rem(48px);
        padding: rem(15px) rem(20px);
        text-align: center;

        span {
          color: $font-4;
          font-weight: 500;
          margin: 0 auto;
          font-size: rem($text-body-font-2);
          line-height: rem($text-body-height-2);
        }
      }

      .data-update-content-item-last-dashed-medium {
        display: flex;
        align-items: center;
        box-sizing: border-box;
        border-radius: rem(2px);
        margin-top: rem(50px);
        background-image: repeating-linear-gradient(
            -25deg,
            #ffb84f,
            #ffb84f 20px,
            transparent 20px,
            transparent 31px,
            #ffb84f 31px
          ),
          repeating-linear-gradient(65deg, #ffb84f, #ffb84f 20px, transparent 20px, transparent 31px, #ffb84f 31px),
          repeating-linear-gradient(155deg, #ffb84f, #ffb84f 20px, transparent 20px, transparent 31px, #ffb84f 31px),
          repeating-linear-gradient(245deg, #ffb84f, #ffb84f 20px, transparent 20px, transparent 31px, #ffb84f 31px);
        background-size: 2px 100%, 100% 2px, 2px 100%, 100% 2px;
        background-position: 0 0, 0 0, 100% 0, 0 100%;
        background-repeat: no-repeat;
        max-width: rem(240px);
        width: 100%;
        // max-height: rem(184px);
        padding: rem(15px) rem(30px);
        text-align: center;
        span {
          color: $font-4;
          font-size: rem(14px);
          font-weight: 400;
          line-height: rem($text-body-height-2);
          margin: 0 auto;
        }
      }
    }
  }

  .color-title-mq-green {
    padding-top: rem(50px);

    .icons {
      svg {
        path {
          stroke: #07e796;
        }
      }
    }
  }

  .data-update-content-title-last-dashed-2-mq {
    display: none;

    @include mq($until: small-tablet) {
      display: block;
      padding-top: rem(50px);
    }
    .data-update-content-item-last-2-mq {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      border-radius: rem(2px);
      border: 2px solid #ffb84f;
      max-width: rem(240px);
      width: 100%;
      // max-height: rem(48px);
      padding: rem(15px) rem(35px);

      span {
        color: $font-4;
        font-weight: 500;
        margin: 0 auto;
        font-size: rem($text-body-font-2);
        line-height: rem($text-body-height-2);
      }
    }

    .arrows {
      padding-bottom: rem(50px);
    }
  }

  .data-update-content-mq {
    display: none;
    @include mq($until: small-tablet) {
      display: block;
    }
    .data-update-content-item-1 {
      margin-bottom: rem(50px);
      display: flex;
      align-items: center;
      box-sizing: border-box;
      border-radius: rem(2px);
      max-width: rem(240px);
      width: 100%;
      // max-height: rem(48px);
      padding: rem(15px) rem(35px);
      text-align: center;
      background-image: repeating-linear-gradient(
          -25deg,
          #ffb84f,
          #ffb84fe7 20px,
          transparent 20px,
          transparent 31px,
          #ffb84f 31px
        ),
        repeating-linear-gradient(65deg, #ffb84f, #ffb84f 20px, transparent 20px, transparent 31px, #ffb84f 31px),
        repeating-linear-gradient(155deg, #ffb84f, #ffb84f 20px, transparent 20px, transparent 31px, #ffb84f 31px),
        repeating-linear-gradient(245deg, #ffb84f, #ffb84f 20px, transparent 20px, transparent 31px, #ffb84f 31px);
      background-size: 2px 100%, 100% 2px, 2px 100%, 100% 2px;
      background-position: 0 0, 0 0, 100% 0, 0 100%;
      background-repeat: no-repeat;

      span {
        margin: 0 auto;
      }
    }

    .data-update-content-item-2 {
      margin-bottom: rem(50px);
      display: flex;
      align-items: center;
      box-sizing: border-box;
      border-radius: rem(2px);
      max-width: rem(240px);
      width: 100%;
      // max-height: rem(48px);
      padding: rem(15px) rem(35px);
      text-align: center;
      background-image: repeating-linear-gradient(
          -25deg,
          #07e796,
          #07e796 20px,
          transparent 20px,
          transparent 31px,
          #07e796 31px
        ),
        repeating-linear-gradient(65deg, #07e796, #07e796 20px, transparent 20px, transparent 31px, #07e796 31px),
        repeating-linear-gradient(155deg, #07e796, #07e796 20px, transparent 20px, transparent 31px, #07e796 31px),
        repeating-linear-gradient(245deg, #07e796, #07e796 20px, transparent 20px, transparent 31px, #07e796 31px);
      background-size: 2px 100%, 100% 2px, 2px 100%, 100% 2px;
      background-position: 0 0, 0 0, 100% 0, 0 100%;
      background-repeat: no-repeat;

      span {
        margin: 0 auto;
      }
    }

    .data-update-content-item-3 {
      margin-top: rem(50px);
      display: flex;
      align-items: center;
      box-sizing: border-box;
      border-radius: rem(2px);
      max-width: rem(240px);
      width: 100%;
      // max-height: rem(48px);
      padding: rem(15px) rem(35px);
      text-align: center;
      background-image: repeating-linear-gradient(
          -25deg,
          $main-500,
          $main-500 20px,
          transparent 20px,
          transparent 31px,
          $main-500 31px
        ),
        repeating-linear-gradient(65deg, $main-500, $main-500 20px, transparent 20px, transparent 31px, $main-500 31px),
        repeating-linear-gradient(155deg, $main-500, $main-500 20px, transparent 20px, transparent 31px, $main-500 31px),
        repeating-linear-gradient(245deg, $main-500, $main-500 20px, transparent 20px, transparent 31px, $main-500 31px);
      background-size: 2px 100%, 100% 2px, 2px 100%, 100% 2px;
      background-position: 0 0, 0 0, 100% 0, 0 100%;
      background-repeat: no-repeat;

      span {
        margin: 0 auto;
      }
    }
  }
}
