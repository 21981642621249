@import 'styles/utils/index';

.trial-wrapper {
  .trial-container {
    background: $white-secondary;
    border-radius: rem(2px);
    max-width: $layout-base-1;
    margin: rem(96px auto 83px);
    padding: rem(104px);

    h2 {
      color: $font-4;
      font-weight: 700;
      font-size: rem(32px);
      line-height: rem(40px);
      margin: 0;

      @include mq($until: small-desktop) {
        text-align: center;
      }
    }

    .trial-description {
      display: flex;
      justify-content: space-between;

      h4 {
        font-weight: 500;
        font-size: rem(20px);
        line-height: rem(28px);
        color: $font-4;
        margin: rem(24px 0 16px);
      }

      .trial-label {
        display: flex;
        align-items: center;
      }

      .trial-inner {
        display: flex;
        flex-direction: column;

        svg {
          font-size: rem(18px);
        }

        p {
          font-weight: 400;
          font-size: rem(18px);
          line-height: rem(28px);
          margin: rem(4px 0);
          margin-left: rem(10px);
          color: $font-3;
        }
      }

      .trial-inner:first-child {
        margin-right: rem(45px);
      }

      @include mq($until: small-desktop) {
        flex-direction: column;
        align-items: center;

        .trial-inner:first-child {
          margin-right: 0;
        }
      }
    }

    @include mq($until: small-tablet) {
      padding: rem(104px 30px);
    }
  }
}
