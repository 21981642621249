@import 'styles/utils/index';

.included-block-loader {
  max-width: calc(#{rem($layout-base-1)} + #{rem(50px)});
  margin: 0 auto;
  padding: rem(82px) rem(25px);

  > div {
    min-height: rem(235px);
    @include skeleton;
    width: 100%;
  }
}
