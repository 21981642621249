@import 'styles/utils/index';

.description-wrapper {
  .card-discount {
    display: flex;
    align-items: center;
    margin-top: rem(18px);
  }

  .card-accounts {
    p {
      font-weight: 500;
      font-size: rem(16px);
      color: $font-3;
      margin: rem(32px 0 4px);
    }

    .card-service {
      display: flex;
      justify-content: space-between;
      align-items: top;
      margin-bottom: rem(8px);

      span {
        color: $font-4;
        padding-right: rem(6px);
      }

      svg {
        font-size: rem(16px);
        min-width: rem(16px);
        margin-top: rem(4px);
      }
    }
  }

  .card-tools {
    &:not(:first-child) {
      margin-top: rem(24px);
    }

    p {
      margin: 0;
      font-size: rem(18px);
      font-weight: 400;
      line-height: rem(24px);
    }

    p:first-child {
      font-weight: 500;
      font-size: rem(16px);
      line-height: rem(20px);
      color: $font-3;
      margin-bottom: rem(16px);
    }

    .card-block {
      display: flex;
      flex-direction: column;

      p {
        letter-spacing: 0.02em;
        color: $font-4;
        margin: 0;
        font-size: rem(18px);
      }

      .card-tool {
        display: flex;
        justify-content: flex-start;
        margin-top: rem(3.5px);
        margin-bottom: rem(24px);

        @include mq($until: desktop) {
          & > div:first-child {
            width: 100%;
          }
        }

        &:not(:last-child) {
          margin-bottom: rem(24px);
        }

        svg:first-child {
          flex-shrink: 0;
          margin-right: rem(10px);
          margin-top: rem(6px);
          font-size: rem(15px);

          width: rem(15px);
          height: rem(15px);

          path {
            fill: $font-4;
          }
        }

        .card-cost-wrapper {
          flex-grow: 1;

          .card-cost {
            display: flex;
            justify-content: space-between;
            align-items: center;

            p {
              color: $font-4;
            }

            span {
              font-weight: 700;
              font-size: rem(18px);
              line-height: rem(24px);
              letter-spacing: 0.02em;
              color: $font-4;
            }
          }
        }

        .card-tool-desc {
          font-weight: 400;
          font-size: rem(16px);
          line-height: rem(24px);
          color: $font-3;
          margin-top: rem(8px);
          display: block;

          > span {
            position: relative;

            &:not(:last-child) {
              padding-right: rem(24px);
            }

            &:first-child:not(:last-child):after {
              content: ' ';
              position: absolute;
              left: rem(8px);
              top: 50%;
              transform: translateY(-50%);
              display: block;
              width: rem(7px);
              height: rem(7px);
              border-radius: 999px;
              background-color: $font-3;
            }
          }
        }
      }
    }

    &:last-child:not(:first-child) {
      margin-top: rem(20px);

      > p:first-child {
        margin-bottom: rem(28px);
      }
    }
  }

  .price-offer {
    color: $font-4 !important;
    font-weight: 500 !important;
    font-size: rem(20px) !important;
    margin: 0;
    line-height: rem(24px) !important;
  }
}
