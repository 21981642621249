@import 'styles/utils/index';

.careers-page {
  margin-left: rem(32px);
  margin-right: rem(32px);

  @include mq($until: iphone-11) {
    margin-left: rem(15px);
    margin-right: rem(15px);
  }

  .careers-infoBlock {
    max-width: rem($layout-base-3);
    margin-left: auto;
    margin-right: auto;

    @include mq($until: iphone-11) {
      h1 {
        margin: 0;
      }
    }
  }

  .career-categories-wrapper {
    max-width: rem($layout-base-1);
    margin-left: auto;
    margin-right: auto;
    margin-top: rem(64px);

    @include mq($until: iphone-11) {
      margin-top: rem(32px);
    }
  }
}
