@import 'styles/utils/index';

.burger-wrapper {
  input[type='checkbox'] {
    display: none;

    &:checked ~ .burger-menu-wrapper {
      display: block;
    }
  }

  .burger-menu-wrapper {
    display: none;
  }

  .burger-btn-wrapper {
    width: rem(26px);
    height: rem(30px);
    position: relative;
    cursor: pointer;
    background: transparent;
    border: none;
    padding: 0;
    display: flex;
    align-items: center;

    &.loading {
      cursor: default;

      .burger-btn-line,
      .burger-btn-line::before,
      .burger-btn-line::after {
        background: $main-200;
      }
    }

    .burger-btn-line,
    .burger-btn-line::before,
    .burger-btn-line::after {
      position: absolute;
      width: 100%;
      height: rem(3px);
      border-radius: rem(6px);
      background: $main-500;
      left: 0;
      content: '';
    }

    .burger-btn-line::before {
      top: rem(-8px);
    }

    .burger-btn-line::after {
      bottom: rem(-8px);
    }
  }
}
