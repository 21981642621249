@import 'styles/utils/index';

.intro-block {
  padding-top: rem(135px);
  padding-bottom: rem(30px);
  display: flex;
  margin: 0 auto;
  max-width: rem($layout-base-2);
  width: 100%;

  @include mq($until: small-tablet) {
    display: block;
    padding-left: rem(15px);
    padding-top: rem(50px);
  }

  @include mq($until: iphone-11) {
    padding-left: 0;
  }

  &__title {
    max-width: rem(385px);
    width: 100%;
    margin-right: 50px;

    @include mq($until: small-tablet) {
      margin: 0 auto;
    }

    @include mq($until: iphone-11) {
      margin: 0;
    }

    h2 {
      margin: 0;
      color: $font-4;

      @include mq($until: small-tablet) {
        font-size: rem(35px);
        text-align: center;
        padding-bottom: rem(10px);
      }

      @include mq($until: iphone-11) {
        font-size: rem(28px);
        line-height: rem(32px);
        text-align: start;
      }
    }
  }

  &__text {
    max-width: rem(568px);
    width: 100%;

    .text-content {
      &__item {
        position: relative;
        margin-bottom: rem(30px);

        span {
          font-weight: 400;
          color: $font-3;
          font-size: rem($text-body-font-1);
          line-height: rem($text-body-height-1);

          @include mq($until: iphone-11) {
            font-size: rem(16px);
            line-height: rem(24px);
          }
        }

        .icon-square {
          position: absolute;
          top: rem(5px);
          left: rem(-28px);
          font-size: rem(12px);
          height: rem(12px);
          fill: none;

          path {
            color: $main-500;
          }
        }
      }
    }
  }
}
