@import 'styles/utils/index';

.course-completion-wrapper {
  background: $white-secondary;
  border: 1px solid $grey-100;
  border-radius: 2px;
  padding: rem(24px);
  max-width: rem(384px);
  width: 100%;
  max-height: rem(700px);

  @include mq($until: wide) {
    margin-top: rem(48px);
    max-width: rem(550px);
    margin-left: auto;
    margin-right: auto;
  }

  h3 {
    font-weight: 700;
    font-size: rem(24px);
    line-height: rem(32px);
    color: $font-4;
    margin: rem(0 0 16px);
  }
}
