@import 'styles/utils/index';

.status-wrapper {
  display: flex;

  &.inactive {
    color: $danger;

    .status-circle {
      width: rem(22px);
      height: rem(22px);
      border-radius: 50%;
      background-color: rgba(247, 41, 66, 0.2);
      position: relative;

      &-inner {
        width: rem(12px);
        height: rem(12px);
        border-radius: 50%;
        background-color: $danger;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  &.active {
    color: $score-a;

    .status-circle {
      width: rem(22px);
      height: rem(22px);
      border-radius: 50%;
      background-color: rgba(64, 204, 173, 0.2);
      position: relative;

      &-inner {
        width: rem(12px);
        height: rem(12px);
        border-radius: 50%;
        background-color: $score-a;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .status-label {
    margin-left: rem(8px);
    text-transform: capitalize;
    font-weight: 500;
  }
}
