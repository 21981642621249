@import 'styles/utils/index';

.sidebar {
  max-width: rem(340px);
  width: 100%;

  @include mq($until: desktop) {
    margin-top: rem(35px);
    max-width: 100%;
    margin-right: auto;
  }

  &-sticky {
    position: sticky;
    top: rem(140px);

    p {
      margin: 0;
      color: $font-4;
    }

    .sidebar-author {
      display: flex;
      align-items: center;
      margin-bottom: rem(30px);
      @include link-styling;

      &:focus {
        @include focus;
      }

      &:hover p:first-child {
        text-decoration: underline;
      }

      > *:not(:last-child) {
        margin-right: rem(22px) !important;
      }

      &-image {
        border-radius: 50%;
        position: relative;
        overflow: hidden;
      }

      &-details {
        p {
          &:first-child {
            font-size: rem(24px);
          }

          &:last-child {
            font-size: rem(16px);
            line-height: rem(32px);
            color: $font-3;
          }
        }
      }
    }

    .sidebar-title {
      margin-bottom: rem(26px);
      font-size: rem(14px);
      text-transform: uppercase;
    }

    .sidebar-parts {
      font-size: rem(18px);
      padding: rem(8px);
      padding-bottom: rem(14px);
      max-height: calc(100vh - 415px);
      overflow-y: auto;
      @include slim-scroll;

      @include mq($until: desktop) {
        max-height: rem(300px);
      }

      .sidebar-part {
        display: grid;
        grid-template-columns: rem(35px 1fr);
        cursor: pointer;
        @include link-styling;

        &:not(:last-child) {
          margin-bottom: rem(26px);
        }

        &:focus {
          @include focus;
        }

        span {
          margin-right: rem(32px);
          color: $font-3;
        }

        p {
          color: $font-3;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &:hover,
        &:focus {
          span,
          p {
            color: $main-500;
            transition: color 0.1s ease-in-out;
          }
        }

        &.active {
          span,
          p {
            color: $main-500;
          }
        }
      }
    }
  }
}
