@import 'styles/utils/index';

.wrapper {
  font-weight: 700;
  font-size: rem(18px);
  color: $secondary-500;
  display: flex;
  align-items: flex-end;

  h3 {
    color: $font-4;
    margin: 0;
    margin-right: rem(8px);
  }

  .price-lg {
    font-size: rem(56px);
  }

  .price-sm {
    font-size: rem(24px);
    line-height: rem(24px);
  }
}
