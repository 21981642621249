@import 'styles/utils/index';

.cards-agents-header-title {
  margin: rem(96px 0 36px);
  color: $font-4;
}

.cards-agents-cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: rem(32px);
  grid-row-gap: rem(32px);

  @include mq($until: desktop) {
    grid-template-columns: 1fr 1fr;
    grid-row-gap: rem(24px);
  }

  @include mq($until: small-desktop) {
    grid-template-columns: 1fr;
  }

  .cards-agents-card {
    position: relative;
    padding: rem(24px);
    border-radius: rem(16px);
    cursor: pointer;
    background-color: $grey-000;
    transition: box-shadow 0.2s ease;

    &:hover {
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

      .cards-agents-link button {
        background: $main-700 !important;
        color: $white !important;

        path {
          stroke: $white !important;
        }
      }
    }

    &:nth-child(3n + 1) {
      .cards-agents-icon {
        background-color: $main-500;
      }
    }

    &:nth-child(3n + 2) {
      .cards-agents-icon {
        background-color: $success-normal;
      }
    }

    &:nth-child(3n + 3) {
      .cards-agents-icon {
        background-color: $warning-normal;
      }
    }

    > *:not(:last-child) {
      margin-bottom: rem(24px);
    }

    .cards-agents-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: rem(104px);
      height: rem(104px);
      border-radius: rem(10px);
      margin-left: auto;
      margin-right: auto;

      svg {
        font-size: rem(76px);
      }
    }

    .cards-agents-inner {
      height: calc(100% - #{rem(128px)}); // where 104 + 24 = 128
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: rem(14px);
      text-align: center;

      @include mq($until: desktop) {
        max-width: 100%;
      }

      .cards-agents-inner-text {
        height: 100%;

        > *:not(:last-child) {
          margin-bottom: rem(4px) !important;
        }

        .cards-agents-title {
          color: $font-4;
          font-size: rem(22px);
          font-weight: 600;
          line-height: rem(30px);
          margin: 0;
        }

        .cards-agents-description {
          color: $font-3;

          p {
            margin: 0;
          }
        }
      }

      .cards-agents-link {
        button {
          color: $main-500;
          font-weight: 700;
          padding: 0 rem(24px);
          border-radius: rem(6px);

          &:disabled {
            color: $main-500 !important;
            background-color: $grey-100 !important;
            cursor: default;
          }

          .cards-agents-arrow {
            margin-left: rem(4px);

            path {
              stroke: $main-500;
            }
          }

          &:hover {
            color: $white;

            path {
              stroke: $white;
            }
          }
        }
      }
    }

    .cards-agents-info {
      position: absolute;
      display: flex;
      align-items: center;
      height: max-content;
      background: none;
      border: none;
      top: rem(9px);
      right: rem(10px);

      svg {
        cursor: pointer;
        font-size: rem(14px);

        path {
          fill: $main-500;
        }
      }
    }

    .cards-agents-type {
      position: absolute;
      top: rem(0);
      right: rem(0);
      background: $main-400;
      color: $white;
      font-size: rem(12px);
      padding: rem(4px 8px);
      line-height: rem(12px);
      border-radius: rem(4px);
      border-bottom-right-radius: 0;
      border-top-left-radius: 0;
      text-transform: capitalize;

      &-free {
        background: $main-400;
      }

      &-paid {
        background-color: $warning-normal;
      }
    }
  }
}
