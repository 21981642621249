@import 'styles/utils/index';

.slider-wrapper {
  width: 100%;
  position: relative;

  .slider-arrow {
    height: rem(48px);
    width: rem(48px);
    cursor: pointer;
    font-size: rem(20px);
    position: absolute;
    text-align: center;
    line-height: rem(44px);
    box-shadow: rem(1px 1px 15px -4.5px) $secondary-100;
    border-radius: 50%;
    transition: transform 0.1s linear;
    border: none;
    z-index: 3;

    transform: translateY(-50%);
    /** Fix z-index issue on Safari */
    -webkit-transform: translateY(-50%) translate3d(0, 0, 0);

    @include mq($until: desktop) {
      &:first-child {
        left: rem(4px);
      }

      &:last-child {
        right: rem(4px);
      }
    }

    &:first-child {
      left: rem(-22px);
    }

    &:last-child {
      right: rem(-22px);
    }

    &:focus {
      @include focus;
    }

    &:active {
      transform: translateY(-50%) scale(0.9);
      -webkit-transform: translateY(-50%) scale(0.9) translate3d(0, 0, 0);
    }

    &:hover {
      transition: box-shadow 0.2s ease-in-out;
      box-shadow: none;
    }

    &.hide {
      display: none;
    }

    &.slider-arrow-top {
      top: 20%;
    }

    &.slider-arrow-middle {
      top: 42%;
    }

    &.slider-arrow-type {
      // .slider-arrow-type-primary
      &-primary {
        background-color: $main-700;

        svg line,
        svg path {
          stroke: $white;
          stroke-width: rem(18px);
        }
      }

      // .slider-arrow-type-secondary
      &-secondary {
        background: $white;
        border: 1px solid $grey-100;

        svg line,
        svg path {
          stroke: $font-4;
          stroke-width: rem(18px);
        }
      }
    }
  }

  .slider {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    scroll-behavior: smooth;
    padding: rem(0 6px);
    scroll-snap-type: x proximity;
    grid-column-gap: rem(30px);

    &::-webkit-scrollbar {
      height: 0;
    }

    &.slider-scroll {
      @include thin-horizontal-scroll;
    }
  }
}
