@import 'styles/utils/index';

.download-sample {
  display: flex;
  flex-direction: column;
  margin: rem(36px 50px 36px 0);
  padding: rem(70px);
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #0d267f;

  @include mq($until: desktop) {
    margin-right: 0;
  }

  @include mq($until: small-desktop) {
    padding: rem(50px);
  }

  &-title {
    h3 {
      color: $white;
      margin: rem(0 0 35px);
      font-size: rem(30px);
      line-height: rem(34px);
    }
  }

  &-button {
    max-width: rem(260px);
  }
}
