@import 'styles/utils/index';

.prospect-wrapper {
  margin-top: rem(152px);

  .container {
    max-width: $layout-base-1;
    margin: 0 auto;

    @include mq($until: wide) {
      padding: rem(0 20px);
    }

    h2 {
      margin-bottom: rem(24px);
    }

    .prospect-title {
      max-width: 1216px;
      padding: 0;

      @include mq($until: wide) {
        text-align: center;
      }
    }
  }
}
