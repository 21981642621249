@import 'styles/utils/index';

.breadcrumb {
  display: flex;
  align-items: center;
  width: 100%;
  margin: rem(30px 0 80px);

  .breadcrumb-element {
    position: relative;

    &:not(:last-child) {
      padding-right: rem(25px);
    }

    .breadcrumb-element-link {
      position: relative;
      @include link-styling;
      color: $font-4;
      cursor: pointer;
      font-size: rem(14px);
      display: block;

      &.disabled {
        pointer-events: none;
        cursor: none;
        color: $font-3;

        span {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: rem(400px);
          display: block;
          @include mq($until: tablet) {
            max-width: rem(150px);
          }
        }
      }

      &:hover {
        color: $font-3;
        transition: color 0.2s ease-in-out;
      }

      &:focus {
        color: $font-3;
        @include focus;
      }
    }

    .breadcrumb-element-divider {
      margin: rem(0 10px);
      font-size: rem(10px) !important;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%) rotate(-90deg);

      path {
        stroke: $secondary-900;
      }
    }
  }
}
