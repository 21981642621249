@import 'styles/utils/index';

.upload-dropzone {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: rem(15px);
  padding: rem(70px 15px);
  max-width: rem(550px);
  box-shadow: 0 7px 27px 3px rgba(31, 105, 179, 0.05);
  background-color: rgba(7, 123, 231, 0.1);
  border: 1px dashed $main-700;
  border-radius: rem(2px);
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    svg {
      @include icon-bouncing;
      animation: iconBouncing 0.5s;
    }

    .upload-button {
      box-shadow: rem(0px 5px 18.8px 1.2px) rgba(65, 106, 141, 0.16);
      border-color: saturate($main-600, 20%);
      background-color: saturate($main-600, 20%);
    }
  }

  .upload-title {
    margin: rem(12px 0 2px);
    font-size: rem(18px);
    color: $font-4;
  }

  .upload-button {
    margin-top: rem(16px);
    width: 100%;
    max-width: rem(185px);
  }

  svg {
    path {
      fill: $main-700;
    }
  }

  &.small {
    padding: rem(26px);
    width: 100%;

    .upload-dropzone-image {
      height: rem(50px);
      position: relative;
    }

    svg {
      margin-bottom: rem(10px);
    }

    .upload-title,
    .upload-button {
      display: none;
    }
  }
}
