@import 'styles/utils/index';

.benefit-card {
  background: white;
  padding: rem(24px);
  border-radius: rem(2px);

  @include mq($until: tablet) {
    margin-bottom: rem(16px);
  }

  &:last-child {
    margin-right: 0px;
  }

  .benefit-icon-wrapper {
    background: rgba(7, 123, 231, 0.102);
    border-radius: rem(2px);
    padding: rem(4px);
    width: rem(32px);
    height: rem(32px);
    margin-bottom: rem(8px);

    .benefit-icon {
      width: rem(24px);
      height: rem(24px);
    }
  }

  p {
    font-weight: 400;
    font-size: rem(18px);
    line-height: rem(28px);
    color: $font-4;
    margin: 0px;
  }
}
