@import 'styles/utils/index';

.learning-platform-wrapper {
  display: flex;
  margin-top: rem(48px);
  color: $font-4;
  gap: rem(50px);

  @include mq($until: wide) {
    flex-direction: column;
    gap: 0;
  }

  &.has-no-course-completion {
    justify-content: center;

    .learning-platform-block {
      margin-right: 0;
    }
  }

  img {
    max-width: 100%;
    object-fit: contain;
  }

  .learning-platform-block {
    max-width: 800px;
    margin-right: rem(32px);

    @include mq($until: wide) {
      max-width: 950px;
      margin-right: 0;
      margin: 0 auto;
    }

    .learning-platform-video-loader {
      height: 456px;
      @include skeleton;
    }

    .learning-platform-description {
      margin-top: rem(24px);

      p,
      li {
        font-weight: 400;
        font-size: rem(18px);
        line-height: rem(28px);
        color: $font-3;
        margin: rem(0 0 24px);
      }

      span {
        font-weight: 700;
        color: $font-4;
      }

      h4 {
        margin-top: rem(72px);
        font-weight: 700;
        font-size: rem(24px);
        line-height: rem(32px);
      }

      .learning-platform-note {
        padding: rem(24px);
        background: rgba(7, 123, 231, 0.05);
        border-radius: 2px;
        border-left: 4px solid $main-500;
        font-weight: 400;
        font-size: rem(18px);
        line-height: rem(28px);
      }

      ul {
        margin-left: rem(13px);

        li {
          margin: rem(0 0 7px 13px);
          list-style: outside;
        }
      }

      img {
        display: block;
        margin: 0 auto;
      }
    }

    .learning-platform-presentation {
      background: $secondary-800;
      opacity: 0.4;
    }
  }
}
