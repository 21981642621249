@import 'styles/utils/index';

.gdb-header-rows {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;

  > * {
    width: calc(25% - #{rem(24px)}) !important;
    height: fit-content;

    &.gdb-header-item {
      margin-right: rem(24px);
    }

    &:not(:last-child) {
      margin-bottom: rem(12px);
    }
  }
}

.gdb-header-row {
  display: flex;
  flex-wrap: wrap;

  > *:not(:last-child) {
    margin-bottom: rem(12px);
  }
}

.gdb-header-item {
  width: 100%;
  display: block;
  position: relative;
  user-select: none;
  min-height: rem(21px);
  font-family: $font;
  font-size: rem(14px);
  cursor: pointer;
  color: $font-2;
  letter-spacing: 0.04em;

  &:hover {
    color: $main-600;

    span {
      color: $main-600;
    }
  }

  &.has-icon {
    padding-left: rem(40px);
  }

  &.disabled {
    cursor: not-allowed;

    label,
    label span {
      color: $font-2;
      cursor: not-allowed;
    }

    svg path {
      stroke: $font-2;
    }
  }

  .gdb-header-item-icon {
    position: absolute;
    left: 0;
    top: 0;

    background: rgba($main-500, 0.1);
    padding: rem(6px);
    min-width: rem(28px);
    width: rem(28px);
    height: rem(28px);
    transform: rotate(0deg) !important;
    margin: rem(0 12px 0px 0);
  }

  .gdb-header-item-desc {
    letter-spacing: 0.04em;
    font-size: rem(13px);
    display: block;
    cursor: pointer;
    margin: rem(2px) 0 0;
    color: $font-4;
    line-height: rem($text-body-height-2);
  }
}
