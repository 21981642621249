@import 'styles/utils/index';

.tooltip-wrapper {
  display: contents;

  div:first-child {
    display: initial;
  }
}

.tooltip {
  position: fixed;
  background: $white;
  color: $main-900;
  padding: rem(8px);
  border-radius: rem(4px);
  z-index: 9999;
  box-shadow: rem(0 2px 8px) #00000026;
  max-width: rem(450px);

  .tooltip-arrow {
    position: absolute;
    bottom: rem(-5px);
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: rem(6px) solid transparent;
    border-right: rem(6px) solid transparent;
    border-top: rem(6px) solid $white;
  }
}
