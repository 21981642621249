@import 'styles/utils/index';

.reading-bar {
  position: sticky;
  top: rem(120.5px);
  transition: width 0.5s ease-in-out;

  @include mq($until: small-tablet) {
    top: rem(122.5px);
  }
}
