@import 'styles/utils/index';

.score-wrapper {
  padding: rem(2px 16px);
  border-radius: rem(2px);
  border: solid 1px;

  &.score-a {
    background-color: $white;
    border-color: $score-a;

    color: $score-a;
  }

  &.score-b {
    background-color: $white;
    border-color: $score-b;

    color: $score-b;
  }

  &.score-c {
    background-color: $white;
    border-color: $score-c;

    color: $score-c;
  }

  &.score-d {
    background-color: $white;
    border-color: $score-d;

    color: $score-d;
  }

  &.score-e {
    background-color: $white;
    border-color: $score-e;

    color: $score-e;
  }

  &.score-n-a {
    background-color: $white;
    border-color: $score-n-a;

    color: $score-n-a;
  }
}

.score-inactive {
  background-color: $white;
  border-color: $score-n-a;

  color: $score-n-a;
}
