@import 'styles/utils/index';

.vacancy-wrapper {
  margin-right: rem(32px);
  margin-left: rem(32px);

  @include mq($until: desktop) {
    margin-right: rem(15px);
    margin-left: rem(15px);
  }

  .info-block {
    margin-bottom: rem(84px);
  }

  .vacancy-container {
    max-width: $layout-base-1;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow: visible;

    @include mq($until: desktop) {
      display: block;
    }

    .vacancy-description {
      width: 100%;
    }

    .apply-form {
      width: rem(488px);
      min-width: rem(488px);
      margin-left: rem(135px);
      position: -webkit-sticky;
      position: sticky;
      top: rem(100px);
      align-self: flex-start;

      @include mq($until: desktop) {
        margin-left: auto;
        margin-right: auto;
        margin-top: rem(64px);
        max-width: rem(488px);
        min-width: rem(0px);
        position: relative;
        top: 0px;
      }
    }
  }
}
