@import 'styles/utils/index';

.tag {
  color: $font-4;
  font-size: rem(16px);
  line-height: rem(24px);
  margin: 0;
}

.tag-type-bullet {
  text-align: left;

  &:before {
    color: $main-500;
    content: '•';
    font-size: rem(23px);
    line-height: rem(24px);
    padding-right: rem(6px);
    vertical-align: rem(-1px);
  }
}
