@import 'styles/utils/index';

.apply-form-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $secondary-500;
  padding: rem(52px);
  border-radius: rem(2px);

  @include mq($until: tablet) {
    padding: rem(24px);
  }

  input,
  button {
    width: 100%;
    border: none;
  }

  > div {
    width: 100%;
    margin-top: rem(32px);

    @include mq($until: tablet) {
      margin-top: rem(24px);
    }

    > div {
      margin: 0;
      max-width: 100%;
      width: 100%;
    }
  }

  h1 {
    margin: rem(0 32px 0);
    text-align: center;
    font-size: rem(40px);
    line-height: rem(48px);

    color: white;
  }

  > span {
    margin-top: rem(8px);
    width: 100%;
    font-weight: 400;
    font-size: rem(16px);
    line-height: rem(24px);
    color: $grey-000;

    > a {
      color: $main-500;
    }
  }

  .cv-input {
    position: relative;

    .input-error-label {
      color: $danger;
      width: 100%;
      text-align: right;
    }

    .file-upload-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;

      cursor: pointer;
      font-weight: 400;
      font-size: rem(18px);
      line-height: rem(28px);
      color: white;

      .file-upload-icon {
        font-size: rem(22px);
        margin-right: rem(8px);
      }
    }

    .file-name {
      max-width: 100%;
      font-weight: 400;
      word-wrap: break-word;
      font-size: rem(18px);
      line-height: rem(28px);
      color: white;
      text-decoration: underline;
      max-height: rem(80px);
      overflow: hidden;
    }
  }
}
