@import 'styles/utils/index';

.cards-preview-wrapper {
  display: flex;
  flex-wrap: wrap;

  @include mq($until: wide) {
    padding: rem(0 15px);
    justify-content: center;
  }

  a {
    text-decoration: unset;
  }

  .card-preview {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    border: 1px solid $grey-100;
    border-radius: rem(2px);
    background: $white;
    margin-bottom: rem(32px);
    width: 100%;

    &:hover {
      transition: box-shadow 0.2s ease-out;
      box-shadow: rem(0 24px 24px) rgba(13, 38, 127, 0.08);
    }

    .card-preview-title {
      color: $font-4;
      margin: rem(24px 0 0);
      padding-bottom: rem(16px);
      border-bottom: 1px solid $grey-100;

      &-lg {
        font-weight: 700;
        line-height: rem(40px);
        font-size: rem(32px);
        margin-bottom: rem(16px);
      }

      &-md {
        font-weight: 500;
        line-height: rem(28px);
        font-size: rem(20px);
        margin-bottom: rem(8px);
      }
    }

    img {
      border-radius: 2px;
    }

    &-lg {
      max-width: 385px;
      padding: rem(32px);

      &:nth-child(3n + 1),
      &:nth-child(3n + 2) {
        margin-right: rem(32px);
      }

      @include mq($until: wide) {
        &:nth-child(2n) {
          margin-right: 0;
        }

        &:nth-child(2n + 1) {
          margin-right: rem(32px);
        }
      }

      @include mq($until: small-desktop) {
        &:nth-child(1n) {
          margin-right: 0;
        }
      }
    }

    &-md {
      max-width: 280px;
      padding: rem(15px);

      &:not(:last-child) {
        margin-right: rem(34px);
      }

      @include mq($until: wide) {
        &:nth-child(3) {
          margin-right: 0;
        }
      }

      @include mq($until: desktop) {
        &:nth-child(3) {
          margin-right: rem(34px);
        }

        &:nth-child(2n) {
          margin-right: 0;
        }
      }

      @include mq($until: tablet) {
        &:nth-child(2n + 1) {
          margin-right: 0;
        }
      }
    }
  }
}
