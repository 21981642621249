@import 'styles/utils/index';

.rc-tabs {
  display: flex;
  flex-direction: column;
  height: 100%;

  .rc-tabs-header {
    display: flex;
    padding: rem(12.5px 72px 0);
    border-bottom: 1px solid $grey-100;
    width: 100%;
    overflow-x: auto;
    @include hidden-scroll;

    > div {
      cursor: pointer;
      padding: rem(5px 0 16px);

      &:not(:first-child) {
        margin-left: rem(32px);
      }

      label {
        cursor: pointer;
      }

      &.rc-tab-active {
        color: $main-600;
        border-bottom: 2px solid $main-600;
      }
    }
  }

  .rc-tabs-children {
    padding: rem(12px 72px 32px);
  }
}
