@import 'styles/utils/index';

.learn-block-wrapper {
  margin-top: rem(152px);

  h2 {
    margin-bottom: rem(20px);
  }

  .learn-block-description {
    max-width: rem(450px);
    font-weight: 500;
    font-size: rem(24px);
    line-height: rem(32px);
    text-align: center;
    color: $font-3;
    margin: rem(0 auto 72px);
  }
}
