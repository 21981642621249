@import 'styles/utils/index';

.course-duration-wrapper {
  .course-duration-left {
    justify-content: flex-start;
  }

  .course-duration-center {
    justify-content: center;
  }

  ul {
    display: flex;

    li {
      display: flex;
      align-items: center;
      margin-right: rem(26px);

      span {
        font-weight: 400;
        font-size: rem(18px);
        line-height: rem(24px);
        color: $font-3;
        margin-left: rem(8px);
      }

      svg {
        font-size: rem(17px) !important;
      }
    }
  }
}
