@import 'styles/utils/index';

.credit-report-loader {
  background: $white;
  box-shadow: rem(0) rem(7px) rem(27px) rem(3px) rgba(31, 105, 179, 0.05);
  border-radius: 7px;
  border: 1px solid $grey-200;
  display: flex;
  align-items: center;
  position: relative;
}

.credit-block {
  position: relative;

  @include mq($until: tablet) {
    max-width: 100%;
  }

  .link {
    color: $main-500;
  }

  h1 {
    margin: rem(16px);
    max-width: rem(512px);
    width: 100%;
    color: $font-4;

    @include mq($until: tablet) {
      font-size: rem(35px);
    }
  }

  h5 {
    margin: 0;
    max-width: rem(592px);
    width: 100%;
    color: $font-2;
    font-weight: 500;

    @include mq($until: small-tablet) {
      margin: 0 rem(10px);
      font-size: rem(20px);
    }
  }

  .searching_table {
    max-width: rem(1268px);
    width: 100%;
    margin: rem(0 auto 192px auto);
    border: 1px solid $grey-200;
    box-shadow: rem(0) rem(7px) rem(27px) rem(3px) rgba(31, 105, 179, 0.03);

    border-radius: 7px;

    &__results {
      padding: rem(16px) rem(0) rem(16px) rem(16px);
      position: relative;
      top: rem(1px);
      border-radius: rem(4px) rem(4px) rem(0) rem(0);
      text-align: left;
      color: #2e404f;
      font-size: rem($text-body-font-1);
      line-height: rem($text-body-height-1);
    }

    .searching_table_description {
      background-color: $white-secondary;
      border-top: rem(1px) solid $grey-200;
      border-bottom: rem(1px) solid $grey-200;
      display: flex;
      font-weight: 400;
      font-size: rem(15px);

      @include mq($until: small-desktop) {
        display: none;
      }

      &__name {
        padding: rem(16px) rem(25px) rem(16px) rem(17.5px);
        max-width: rem(301px);
        width: 100%;
        color: $font-4;

        &:hover {
          background: #e8f0fe86;
        }
      }

      &__city {
        padding: rem(16px) rem(25px) rem(16px) rem(17.5px);
        max-width: rem(247px);
        width: 100%;
        color: $font-4;

        &:hover {
          background: #e8f0fe86;
        }
      }

      &__address {
        padding: rem(16px) rem(25px) rem(16px) rem(17.5px);
        max-width: rem(302px);
        width: 100%;
        color: $font-4;

        &:hover {
          background: #e8f0fe86;
        }
      }

      &__regno {
        padding: rem(16px) rem(25px) rem(16px) rem(17.5px);
        max-width: rem(196px);
        width: 142%;
        color: $font-4;

        &:hover {
          background: #e8f0fe86;
        }
      }

      &__status {
        padding: rem(16px) rem(25px) rem(16px) rem(17.5px);
        max-width: rem(162px);
        width: 100%;
        color: $font-4;

        &:hover {
          background: #e8f0fe86;
        }
      }

      &__action {
        padding: rem(16px) rem(25px) rem(16px) rem(17.5px);
        max-width: rem(150px);
        width: 100%;
        color: $font-4;

        &:hover {
          background: #e8f0fe86;
        }
      }
    }

    .searching_table_item {
      background: $white;
      display: flex;

      &:not(:last-child) {
        border-bottom: rem(1px) solid $grey-200;
      }

      @include mq($until: small-desktop) {
        display: none;
      }

      &:hover {
        background: #e9f4f486;
      }

      .searching_table_item__name {
        padding: rem(16px) rem(25px) rem(16px) rem(17.5px);
        max-width: rem(301px);
        width: 100%;
        font-weight: 400;
        color: $font-3;
        font-size: rem(14px);
        line-height: rem(20px);
        text-transform: uppercase;
        align-self: center;
      }

      .searching_table_item__city {
        padding: rem(16px) rem(25px) rem(16px) rem(17.5px);
        max-width: rem(247px);
        width: 100%;
        font-size: rem(14px);
        line-height: rem(20px);
        font-weight: 400;
        color: $font-3;
        text-transform: uppercase;
        align-self: center;
      }

      .searching_table_item__address {
        padding: rem(16px) rem(25px) rem(16px) rem(17.5px);
        max-width: rem(302px);
        width: 100%;
        font-size: rem(14px);
        line-height: rem(20px);
        font-weight: 400;
        color: $font-3;
        text-transform: uppercase;
        position: relative;
        align-self: center;

        svg {
          position: absolute;
          top: rem(15px);
          left: rem(15px);
          width: rem(13px);
          height: rem(16px);
          fill: rgb(0, 155, 233);
        }

        span {
          margin-left: rem(18px);
        }
      }

      .searching_table_item__regno {
        padding: rem(16px) rem(25px) rem(16px) rem(17.5px);
        max-width: rem(196px);
        width: 142%;
        color: $font-3;
        font-weight: 400;
        font-size: rem(14px);
        align-self: center;
      }

      .searching_table_item__status {
        padding: rem(16px) rem(25px) rem(16px) rem(17.5px);
        max-width: rem(162px);
        width: 100%;
        display: flex;
        color: #2a3846;
        font-size: rem(14px);
        font-weight: 500;
        position: relative;

        .circle-Active,
        .circle-Inactive {
          position: absolute;
          left: rem(15px);
          margin: 0;
          border-radius: 50%;
          display: inline-block;
          padding: rem(5px);
          align-self: center;
          opacity: 0.2;
        }

        .circle-Active {
          border: rem(5px) solid #07e796;
        }

        .circle-Inactive,
        .NonActive,
        .Unactive {
          border: rem(5px) solid #f72942;
        }

        .status-default {
          background-color: rgb(0, 155, 233) !important;
          position: absolute;
          left: rem(15px);
          margin: 0;
          border-radius: 50%;
          border: rem(5px) solid #e9f4f4;
          display: inline-block;
          padding: rem(5px);
          align-self: center;
        }

        .Active {
          background-color: #07e796 !important;
          position: absolute;
          left: rem(15px);
          margin: 0;
          border-radius: 50%;
          border: rem(5px) solid #e9f4f4;
          display: inline-block;
          padding: rem(5px);
        }

        .Inactive {
          background-color: #f72942 !important;
          position: absolute;
          left: rem(15px);
          margin: 0;
          border-radius: 50%;
          border: rem(5px) solid #e9f4f4;
          display: inline-block;
          padding: rem(5px);
        }

        .NonActive {
          background-color: #f72942 !important;
          position: absolute;
          left: rem(15px);
          margin: 0;
          border-radius: 50%;
          border: rem(5px) solid #e9f4f4;
          display: inline-block;
          padding: rem(5px);
        }

        .Unactive {
          background-color: #f72942 !important;
          position: absolute;
          left: rem(15px);
          margin: 0;
          border-radius: 50%;
          border: rem(5px) solid #e9f4f4;
          display: inline-block;
          padding: rem(5px);
        }

        .status-null {
          margin-left: rem(16px) !important;
        }

        .status-Active {
          color: #07e796;
        }

        .status-Inactive,
        .status-Unactive,
        .status-NonActive {
          color: #f72942;
        }

        span {
          margin-left: rem(25px);
          align-self: center;
        }
      }

      .searching_table_item__action {
        position: relative;
        padding: rem(16px) rem(25px) rem(16px) rem(17.5px);
        max-width: rem(150px);
        width: 100%;
        color: $main-500;
        font-size: rem(14px);
        font-weight: 700;
        cursor: pointer;
        align-self: center;
        white-space: nowrap;

        span {
          padding: rem(6px 13px);
          border-radius: 2px;
          background-color: rgba($main-500, 0.1);
          transition: background-color 0.1s;

          &:hover {
            background-color: rgba($main-500, 0.2);
          }
        }
      }
    }

    .searching_table_item_responsive {
      border-bottom: rem(1px) solid #e0e4e6;
      background: $white;
      display: none;

      @include mq($until: small-desktop) {
        display: flex;
        flex-wrap: wrap;
      }

      &:hover {
        background: #e9f4f4;
      }

      &__name {
        padding: rem(14px) rem(25px) rem(14px) rem(17.5px);
        width: 50%;
        font-weight: 700;
        color: $font-3;
        font-size: rem(14px);
        text-transform: uppercase;

        p {
          color: $font-4;
          font-size: rem(14px);
          font-weight: 700;
        }
      }

      &__city {
        padding: rem(14px) rem(25px) rem(14px) rem(17.5px);
        width: 50%;
        font-size: rem(14px);
        font-weight: 500;
        color: $font-3;
        text-transform: uppercase;

        p {
          color: $font-4;
          font-size: rem(14px);
          font-weight: 700;
        }
      }

      &__address {
        padding: rem(14px) rem(25px) rem(14px) rem(17.5px);
        width: 50%;
        color: $font-3;
        font-size: rem(14px);
        font-weight: 500;
        text-transform: uppercase;
        position: relative;

        p {
          color: $font-4;
          font-size: rem(14px);
          font-weight: 700;
        }

        svg {
          position: absolute;
          top: rem(68.8px);
          left: rem(15px);
          fill: $font-3;
        }

        span {
          margin-left: rem(18px);
        }
      }

      &__regno {
        padding: rem(14px) rem(25px) rem(14px) rem(17.5px);
        width: 50%;
        color: $font-3;
        font-weight: 500;
        font-size: rem(14px);

        p {
          color: $font-4;
          font-size: rem(14px);
          font-weight: 700;
        }
      }

      &__status {
        padding: rem(14px) rem(25px) rem(14px) rem(17.5px);
        width: 100%;
        color: #2a3846;
        font-size: rem(14px);
        font-weight: 500;
        position: relative;

        p {
          color: $font-4;
          font-size: rem(14px);
          font-weight: 700;
        }

        .circle-Active,
        .circle-Inactive {
          position: absolute;
          left: rem(15px);
          margin: 0;
          border-radius: 50%;
          display: inline-block;
          padding: rem(5px);
          align-self: center;
          opacity: 0.2;
        }

        .circle-Active {
          border: rem(5px) solid #07e796;
        }

        .circle-Inactive,
        .NonActive,
        .Unactive {
          border: rem(5px) solid #f72942;
        }

        .status-default {
          background-color: rgb(0, 155, 233) !important;
          position: absolute;
          left: rem(15px);
          margin: 0;
          border-radius: 50%;
          border: rem(5px) solid #e9f4f4;
          display: inline-block;
          padding: rem(5px);
        }

        .Active {
          background-color: #20ba85 !important;
          position: absolute;
          left: rem(15px);
          margin: 0;
          border-radius: 50%;
          border: rem(5px) solid #e9f4f4;
          display: inline-block;
          padding: rem(5px);
        }

        .Inactive {
          background-color: #ed493b !important;
          position: absolute;
          left: rem(15px);
          margin: 0;
          border-radius: 50%;
          border: rem(5px) solid #e9f4f4;
          display: inline-block;
          padding: rem(5px);
        }

        .NonActive {
          background-color: #ed493b !important;
          position: absolute;
          left: rem(15px);
          margin: 0;
          border-radius: 50%;
          border: rem(5px) solid #e9f4f4;
          display: inline-block;
          padding: rem(5px);
        }

        .Unactive {
          background-color: #ed493b !important;
          position: absolute;
          left: rem(15px);
          margin: 0;
          border-radius: 50%;
          border: rem(5px) solid #e9f4f4;
          display: inline-block;
          padding: rem(5px);
        }

        .status-null {
          margin-left: rem(13px) !important;
        }

        .status-Active {
          color: #07e796;
        }

        .status-Inactive,
        .status-Unactive,
        .status-NonActive {
          color: #f72942;
        }

        span {
          margin-left: rem(28px);
          margin-top: rem(18px);
        }
      }

      &__action {
        padding: rem(14px) rem(25px) rem(14px) rem(17.5px);
        width: 100%;
        text-align: center;
        color: #009be9;
        font-size: rem(14px);
        font-weight: 500;
        text-align: center;

        span {
          cursor: pointer;
          display: block;
          margin-top: rem(20px);
          margin-bottom: rem(10px);
          font-size: rem($text-body-font-2);
          line-height: rem($text-body-height-2);
        }

        p {
          margin: 0;

          @include mq($until: small-desktop) {
            display: none;
          }
        }
      }
    }
  }

  :global {
    .input-error-label {
      top: rem(125px);
      left: rem(100px);
      color: $danger;

      @include mq($until: desktop) {
        top: rem(59.2px);
        left: 0 !important;
      }
    }
  }

  .loading-table {
    margin: 0 auto;
    max-width: rem(1268px);
    width: 100%;
    height: rem(230px);
    background: $white;
    box-shadow: rem(0) rem(7px) rem(27px) rem(3px) rgba(31, 105, 179, 0.03);
    border-radius: rem(7px);
    border: 1px solid $grey-200;
    text-align: center;
    display: flex;
    align-items: center;

    .lds-ring {
      width: rem(58px);
      height: rem(58px);
    }
  }
}
