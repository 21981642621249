@import 'styles/utils/index';

.blog-page {
  padding: 0 rem(15px);

  h1 {
    color: $font-4;
    text-align: center;
    margin: rem(152px 0 -60px);

    @include mq($until: iphone-11) {
      text-align: start;
      margin: rem(48px 0 -60px);
    }
  }

  .topics {
    > div:nth-child(1) {
      color: $main-500;
      border-bottom: rem(2px) $main-500 solid;
    }
  }

  .benefits-blog__right {
    > div {
      @include mq($until: small-desktop) {
        margin-top: rem(20px);
      }
    }
  }

  .blog-page-benefits {
    background-color: transparent;
    margin-top: 0;
    padding-top: 0;
  }
}
