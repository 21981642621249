@import 'styles/utils/index';

.confirm-password-template-skeleton-wrapper {
  margin: rem(120px auto 0);

  .confirm-password-template-skeleton-title {
    color: transparent;
    width: max-content;
    margin: 0 auto rem(8px);
    font-size: rem(52px);
    @include skeleton;
  }

  .confirm-password-template-skeleton-form {
    display: grid;
    grid-template-columns: auto;
    grid-row-gap: rem(24px);
    max-width: rem(500px);
    margin: rem(34px auto 16px);

    .confirm-password-template-skeleton-item {
      width: 100%;
      height: rem(66px);
      @include skeleton;
    }
  }
}
