@import 'styles/utils/index';

.profile-description {
  max-width: rem($layout-base-1);
  width: rem(100%);
  padding: rem(160px 0);
  margin: 0 auto;

  @include mq($until: wide) {
    padding: rem(70px);

    &.alternative {
      padding: rem(130px 70px);

      .profile-description-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        > *:first-child {
          margin-right: 0;
          margin-left: rem(15px);
          padding-right: rem(15px);

          @include mq($until: small-desktop) {
            margin-left: 0;
          }
        }
      }
    }
  }

  @include mq($until: desktop) {
    padding: rem(50px);

    &.alternative {
      padding: rem(130px 70px);

      .profile-description-wrapper {
        > *:first-child {
          margin-left: 0;
          padding-right: 0;
        }

        .profile-description-text {
          > div {
            max-width: 100%;
            text-align: center;
          }

          &-title {
            max-width: 100%;
            text-align: center;
          }
        }
      }

      .profile-description-wrapper {
        &.invert {
          > *:first-child {
            margin-right: 0;
            margin-left: 0;
          }
        }
      }
    }
  }

  @include mq($until: small-desktop) {
    padding: rem(50px);
  }

  @include mq($until: iphone-11) {
    padding: rem(50px 0);

    &.alternative {
      padding: rem(130px 0px);
    }

    p,
    .profile-description-text-title {
      padding: rem(0 15px);
    }
  }

  .profile-description-wrapper-title {
    text-align: center;
    margin: rem(0 0 66px);
    color: $font-4;

    @include mq($until: small-desktop) {
      margin: rem(0 0 33px);
      font-size: rem(40px);
      line-height: rem(42px);
    }

    @include mq($until: iphone-11) {
      font-size: rem(32px);
      line-height: rem(36px);
      text-align: start;
    }
  }

  .profile-description-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @include mq($until: small-desktop) {
      flex-wrap: wrap;
      flex-direction: row;
    }

    &.invert {
      flex-direction: row-reverse;

      > *:first-child {
        margin-right: 0;
        margin-left: rem(75px);

        @include mq($until: small-desktop) {
          margin-left: 0;
        }
      }
    }

    &.title-position {
      @include mq($until: small-desktop) {
        &-stats,
        &-out {
          .profile-description-stats-wrapper {
            order: 1;
          }

          .profile-description-text {
            order: 2;
          }
        }
      }
    }

    > *:first-child {
      margin-left: 0;
      margin-right: rem(75px);

      @include mq($until: small-desktop) {
        margin-right: 0;
      }
    }

    a {
      max-width: rem(314px);
      width: 100%;

      .touchData__button {
        white-space: nowrap;
        padding: rem(15px 20px);
        max-width: rem(314px);
        width: 100%;

        &.search {
          height: rem(64px);
        }
      }
    }

    .profile-description-text-title,
    .profile-description-stats-title {
      margin: rem(0 0 34px);
      color: $font-4;

      @include mq($until: small-desktop) {
        text-align: center;

        font-size: rem(40px);
        line-height: rem(42px);
      }

      @include mq($until: iphone-11) {
        text-align: start !important;
        font-size: rem(32px);
        line-height: rem(36px);
      }
    }

    .profile-description-text {
      .profile-description-text-title {
        max-width: rem(496px);
        width: 100%;
        margin-bottom: rem(20px);

        @include mq($until: small-desktop) {
          max-width: 100%;
        }
      }

      > div {
        max-width: rem(593px);
        width: 100%;
        color: $font-3;
        font-size: rem(18px);
        line-height: rem($text-body-height-2);

        @include mq($until: small-desktop) {
          max-width: 100%;
        }

        @include mq($until: iphone-11) {
          p {
            text-align: start;
          }
        }

        ul {
          list-style-type: initial;
          padding-left: rem(20px);
        }
      }
    }

    .profile-description-stats-wrapper {
      max-width: rem(425px);
      width: 100%;
      align-self: center;

      &.alternative {
        margin: auto 0;

        @include mq($until: small-desktop) {
          max-width: rem(425px);
        }

        @include mq($until: iphone-11) {
          padding: rem(0 40px);
        }

        .profile-description-stat-count {
          &.super-small {
            font-size: rem(24px) !important;
            line-height: rem(24px) !important;
          }

          &.small {
            font-size: rem(36px) !important;
            line-height: rem(36px) !important;
          }
        }
      }

      @include mq($until: small-desktop) {
        max-width: 100%;
      }

      .profile-description-stats-title {
        margin: rem(0 0 52px);
      }

      .profile-description-stats {
        margin-left: auto;
        max-width: rem(385px);
        width: 100%;
        background-repeat: no-repeat;
        position: relative;
        height: 100%;

        @include mq($until: small-desktop) {
          margin: auto;
        }

        $blue-background: $main-700;
        $blue-color-count: $white;
        $blue-color-info: $blue-color-count;

        $transparent-background: transparent;
        $transparent-border: $secondary-500;
        $transparent-color-count: $secondary-500;
        $transparent-color-info: $grey-700;

        $white-background: $white;
        $white-color-count: $main-700;
        $white-color-info: $grey-700;

        $grey-background: $white-secondary;
        $grey-color-count: $secondary-500;
        $grey-color-info: $grey-700;

        $red-background: #b3071d;
        $red-color-count: $white;
        $red-color-info: $white;

        $green-background: #036542;
        $green-color-count: $white;
        $green-color-info: $white;

        $orange-background: #814d00;
        $orange-color-count: $white;
        $orange-color-info: $white;

        .stat-info-default {
          color: $grey-color-info;
          font-weight: 700;
          font-size: rem(14px);
          line-height: rem(16px);
          text-align: left;
        }

        .stat-count-default {
          font-size: rem(40px);
          font-weight: 700;
          line-height: 100%;
          text-align: left;
        }

        &.blue-white-grey {
          background-position: rem(10px) top;
          min-height: rem(346px);

          .profile-description-stat {
            &:nth-child(1) {
              background-color: $blue-background;
              z-index: 1;
              left: 0;
              top: rem(66px);

              .profile-description-stat-count {
                color: $blue-color-count;
              }

              .profile-description-stat-info {
                color: $blue-color-info;
              }
            }

            &:nth-child(2) {
              background-color: $white-background;
              z-index: 2;
              left: rem(104px);
              top: rem(172px);
              box-shadow: 0px 48px 48px rgba(12, 37, 127, 0.08);

              .profile-description-stat-count {
                color: $white-color-count;
              }

              .profile-description-stat-info {
                color: $white-color-info;
              }
            }

            &:nth-child(3) {
              background-color: $grey-background;
              z-index: 1;
              left: rem(84px + 124px);
              top: rem(84px);

              .profile-description-stat-count {
                color: $grey-color-count;
              }

              .profile-description-stat-info {
                color: $grey-color-info;
              }
            }
          }
        }

        &.blue-white-grey-alternative {
          background-position: rem(10px) top;
          min-height: rem(346px);
          margin-left: 0;

          @include mq($from: small-desktop, $until: 1115px) {
            margin-top: rem(16px);
          }

          @include mq($until: small-desktop) {
            margin-left: auto;
          }

          .profile-description-stat {
            width: 100%;
            max-width: rem(384px);
            height: rem(104px);
            padding: rem(25px 24px);
            border-radius: 2px;

            &:nth-child(1) {
              background-color: $blue-background;
              z-index: 1;

              .profile-description-stat-info {
                @extend .stat-info-default;
                color: $white;
              }

              .profile-description-stat-count {
                @extend .stat-count-default;
                color: $blue-color-count;
              }
            }

            &:nth-child(2) {
              background-color: $white-background;
              z-index: 2;
              left: rem(32px);
              top: rem(144px);
              box-shadow: 0px 48px 48px rgba(12, 37, 127, 0.08);

              .profile-description-stat-count {
                @extend .stat-count-default;
                color: $white-color-count;
              }

              .profile-description-stat-info {
                @extend .stat-info-default;
                color: $white-color-info;
              }
            }

            &:nth-child(3) {
              background-color: $grey-background;
              z-index: 1;
              top: rem(288px);

              .profile-description-stat-count {
                @extend .stat-count-default;
                color: $grey-color-count;
              }

              .profile-description-stat-info {
                @extend .stat-info-default;
              }
            }
          }
        }

        &.grey-red-transparent {
          background-position: left top;
          min-height: rem(306px);

          .profile-description-stat {
            &:nth-child(1) {
              background-color: $blue-background;
              z-index: 1;
              left: 0;
              top: 0;

              .profile-description-stat-count {
                color: $blue-color-count;
              }

              .profile-description-stat-info {
                color: $blue-color-info;
              }
            }

            &:nth-child(2) {
              background-color: $red-background;
              z-index: 2;
              left: rem(84px);
              top: rem(32px + 124px);
              box-shadow: 0px 48px 48px rgba(12, 37, 127, 0.08);

              .profile-description-stat-count {
                color: $red-color-count;
              }

              .profile-description-stat-info {
                color: $red-color-info;
              }
            }

            &:nth-child(3) {
              background-color: $transparent-background;
              border: 1px solid $transparent-border;
              z-index: 1;
              left: rem(64px + 124px);
              top: rem(39px + 13px);

              .profile-description-stat-count {
                color: $transparent-color-count;
              }

              .profile-description-stat-info {
                color: $transparent-color-info;
              }
            }
          }
        }

        &.white-blue-white-alternative {
          background-position: left top;
          min-height: rem(306px);
          margin-top: rem(-100px);

          @include mq($until: wide) {
            margin-top: rem(30px);
          }

          .profile-description-stat {
            width: 100%;
            max-width: rem(384px);
            height: rem(160px);
            padding: rem(50px 40px);

            &:nth-child(1) {
              background-color: $white;
              border-radius: 2px 2px 0 0;
              border: 1px solid $secondary-500;
              z-index: 1;

              .profile-description-stat-info {
                @extend .stat-info-default;
                opacity: 1;
              }

              .profile-description-stat-count {
                @extend .stat-count-default;
                line-height: rem(40px);
                color: $secondary-500;
              }
            }

            &:nth-child(2) {
              background-color: $white-background;
              z-index: 2;
              left: rem(104px);
              top: rem(280px);
              border-radius: 2px 2px 0 0;
              box-shadow: 0px 48px 48px rgba(12, 37, 127, 0.08);
              transition: 0.2s ease;

              @include mq($until: tablet) {
                max-width: rem(350px);
              }

              @include mq($until: 500px) {
                max-width: rem(320px);
              }

              @include mq($until: small-tablet) {
                max-width: rem(384px);
                top: rem(320px);
                left: 0;
                border-radius: 0 0 2px 2px;
              }

              .profile-description-stat-info {
                @extend .stat-info-default;
              }

              .profile-description-stat-count {
                @extend .stat-count-default;
                color: $main-700;
                line-height: rem(40px);
              }
            }

            &:nth-child(3) {
              background-color: $secondary-500;
              border: 1px solid $transparent-border;
              border-radius: 0;
              z-index: 1;
              top: rem(160px);

              .profile-description-stat-info {
                @extend .stat-info-default;
                color: $white;
              }

              .profile-description-stat-count {
                @extend .stat-count-default;
                color: $white;
                line-height: rem(40px);
              }

              &::after {
                content: ' ';
                position: absolute;
                border-radius: 0 0 2px 2px;
                width: 100%;
                max-width: rem(384px);
                height: rem(160px);
                background-color: $grey-100;
                top: rem(159px);
                left: rem(-1px);

                @include mq($until: small-tablet) {
                  display: none;
                }
              }
            }
          }
        }

        &.green-grey-white {
          background-position: rem(18px) top;
          min-height: rem(322px);

          .profile-description-stat {
            &:nth-child(1) {
              background-color: $green-background;
              z-index: 2;
              left: 0;
              top: rem(83px);
              animation: pulsation 1s forwards;

              .profile-description-stat-count {
                color: $green-color-count;
              }

              .profile-description-stat-info {
                color: $green-color-info;
              }
            }

            &:nth-child(2) {
              background-color: $grey-background;
              z-index: 1;
              left: rem(104px);
              top: rem(185px);

              .profile-description-stat-count {
                color: $grey-color-count;
              }

              .profile-description-stat-info {
                color: $grey-color-info;
              }
            }

            &:nth-child(3) {
              background-color: $white-background;
              z-index: 1;
              left: rem(170px + 18px);
              top: rem(36px);
              box-shadow: 0px 48px 48px rgba(12, 37, 127, 0.08);

              .profile-description-stat-count {
                color: $white-color-count;
              }

              .profile-description-stat-info {
                color: $white-color-info;
              }
            }
          }
        }

        &.grey-white-blue-alternative {
          background-position: rem(18px) top;
          min-height: rem(322px);
          margin: rem(-50px 25px);

          @include mq($until: 1115px) {
            margin: rem(30px 0 0 80px);
          }

          @include mq($until: small-tablet) {
            margin: rem(30px 0 0 60px);
          }

          @include mq($until: iphone-x) {
            margin-left: rem(60px);
          }

          .profile-description-stat {
            width: 100%;
            max-width: rem(176px);
            height: rem(260px);
            padding: rem(24px 32px);
            border-radius: 2px;
            justify-content: space-between;

            &:nth-child(1) {
              background-color: $white;
              z-index: 2;
              left: rem(24px);
              box-shadow: 0px 48px 48px rgba(12, 37, 127, 0.08);
              animation: pulsation 1s forwards;

              @include mq($from: 1115px, $until: wide) {
                left: rem(168px);
              }

              .profile-description-stat-info {
                @extend .stat-info-default;
              }

              .profile-description-stat-count {
                @extend .stat-count-default;
                color: $main-700;
                font-size: rem(52px);
                line-height: rem(52px);
              }
            }

            &:nth-child(2) {
              background-color: $blue-background;
              z-index: 1;
              left: rem(224px);
              top: rem(132px);
              transition: 0.2s ease;

              @include mq($from: 1115px, $until: wide) {
                left: rem(288px);
                top: rem(244px);
              }

              @include mq($until: small-tablet) {
                left: rem(136px);
                top: rem(244px);
              }

              @include mq($until: iphone-x) {
                left: rem(88px);
              }

              .profile-description-stat-info {
                @extend .stat-info-default;
                color: $white;
              }

              .profile-description-stat-count {
                @extend .stat-count-default;
                color: $white;
                font-size: rem(96px);
                line-height: rem(96px);
              }
            }

            &:nth-child(3) {
              background-color: $grey-000;
              z-index: 1;
              left: rem(-176px);
              top: rem(82px);
              transition: 0.2s ease;

              @include mq($from: 1115px, $until: wide) {
                left: rem(-26px);
                top: rem(62px);
              }

              @include mq($until: small-desktop) {
                left: rem(-112px);
                top: rem(182px);
              }

              .profile-description-stat-info {
                @extend .stat-info-default;
                color: $white-color-info;
              }

              .profile-description-stat-count {
                @extend .stat-count-default;
                color: $secondary-500;
                font-size: rem(36px);
                line-height: rem(36px);
              }
            }
          }
        }

        &.white-transparent-orange {
          background-position: rem(69px) top;
          min-height: rem(310px);

          .profile-description-stat {
            &:nth-child(1) {
              background-color: $white-background;
              z-index: 1;
              left: 0;
              top: rem(83px);
              box-shadow: 0px 48px 48px rgba(12, 37, 127, 0.08);

              .profile-description-stat-count {
                color: $white-color-count;
              }

              .profile-description-stat-info {
                color: $white-color-info;
              }
            }

            &:nth-child(2) {
              background-color: $transparent-background;
              border: 1px solid $transparent-border;
              z-index: 1;
              left: rem(16px + 124px);
              top: rem(42px);

              .profile-description-stat-count {
                color: $transparent-color-count;
              }

              .profile-description-stat-info {
                color: $transparent-color-info;
              }
            }

            &:nth-child(3) {
              background-color: $orange-background;
              z-index: 2;
              left: rem(116px + 124px);
              top: rem(141px);
              box-shadow: 0px 48px 48px rgba(255, 184, 79, 0.18);

              @include mq($until: iphone-x) {
                left: rem(180px);
              }

              .profile-description-stat-count {
                color: $orange-color-count;
              }

              .profile-description-stat-info {
                color: $orange-color-info;
              }
            }
          }
        }

        &.blue-grey-white-alternative {
          background-position: rem(69px) top;
          min-height: rem(310px);
          transition: 0.2s ease;

          @include mq($until: wide) {
            margin: rem(40px auto auto);
            max-width: 100%;
          }

          @include mq($until: small-tablet) {
            margin-left: 0;
            margin: rem(140px auto);
          }

          .profile-description-stat {
            width: 100%;
            max-width: rem(208px);
            height: rem(208px);
            padding: rem(24px);
            border-radius: 2px;
            justify-content: space-between;

            &:nth-child(1) {
              background-color: $white-background;
              z-index: 3;
              left: rem(328px);
              top: rem(120px);
              box-shadow: 0px 48px 48px rgba(12, 37, 127, 0.08);
              transition: 0.2s ease;

              @include mq($until: tablet) {
                top: rem(232px);
                left: 25%;
              }

              @include mq($until: small-tablet) {
                top: rem(360px);
              }

              @include mq($until: iphone-x) {
                left: rem(12%);
              }

              .profile-description-stat-info {
                @extend .stat-info-default;
                color: $grey-700;
              }

              .profile-description-stat-count {
                @extend .stat-count-default;
                color: $secondary-500;
                font-size: rem(44px);
                line-height: rem(44px);
              }
            }

            &:nth-child(2) {
              background-color: $secondary-500;
              z-index: 1;
              top: rem(35px);

              @include mq($until: small-tablet) {
                top: rem(132px);
                left: 27%;
              }

              @include mq($until: iphone-x) {
                left: 8%;
              }

              .profile-description-stat-info {
                @extend .stat-info-default;
                color: $white;
              }

              .profile-description-stat-count {
                @extend .stat-count-default;
                color: $white;
                font-size: rem(44px);
                line-height: rem(44px);
              }
            }

            &:nth-child(3) {
              background-color: $grey-100;
              z-index: 2;
              left: rem(208px);

              @include mq($until: small-tablet) {
                top: rem(-95px);
                left: 25%;
              }

              @include mq($until: iphone-x) {
                left: 12%;
              }

              .profile-description-stat-info {
                @extend .stat-info-default;
                color: $grey-700;
              }

              .profile-description-stat-count {
                @extend .stat-count-default;
                color: $secondary-500;
                font-size: rem(96px);
                line-height: rem(96px);
              }
            }
          }
        }

        &.white-blue-grey {
          background-position: rem(71px) top;
          min-height: rem(327px);

          .profile-description-stat {
            &:nth-child(1) {
              background-color: $white-background;
              z-index: 1;
              left: 0;
              top: rem(15px);
              box-shadow: 0px 48px 48px rgba(12, 37, 127, 0.08);

              .profile-description-stat-count {
                color: $white-color-count;
              }

              .profile-description-stat-info {
                color: $white-color-info;
              }
            }

            &:nth-child(2) {
              background-color: $blue-background;
              z-index: 2;
              left: rem(22px + 124px);
              top: rem(118px);
              box-shadow: 0px 48px 48px rgba(12, 37, 127, 0.08);

              .profile-description-stat-count {
                color: $blue-color-count;
              }

              .profile-description-stat-info {
                color: $blue-color-info;
              }
            }

            &:nth-child(3) {
              background-color: $grey-background;
              z-index: 1;
              left: rem(101px + 124px);
              top: rem(13px);

              .profile-description-stat-count {
                color: $grey-color-count;
              }

              .profile-description-stat-info {
                color: $grey-color-info;
              }
            }
          }
        }

        &.red-grey-transparent {
          background-position: left rem(15px);
          min-height: rem(318px);

          .profile-description-stat {
            &:nth-child(1) {
              background-color: $red-background;
              z-index: 1;
              left: rem(23px);
              top: rem(0);
              box-shadow: 0px 48px 48px rgba(246, 41, 66, 0.18);

              .profile-description-stat-count {
                color: $red-color-count;
              }

              .profile-description-stat-info {
                color: $red-color-info;
              }
            }

            &:nth-child(2) {
              background-color: $grey-background;
              z-index: 1;
              left: rem(108px);
              top: rem(122px + 15px);

              .profile-description-stat-count {
                color: $grey-color-count;
              }

              .profile-description-stat-info {
                color: $grey-color-info;
              }
            }

            &:nth-child(3) {
              background-color: $transparent-background;
              border: 1px solid $transparent-border;
              z-index: 2;
              left: rem(199px);
              top: rem(35px + 15px);
              animation: pulsation 1s forwards;

              .profile-description-stat-count {
                color: $transparent-color-count;
              }

              .profile-description-stat-info {
                color: $transparent-color-info;
              }
            }
          }
        }

        &.white-transparent-green {
          background-position: rem(45px) top;
          min-height: rem(318px);

          .profile-description-stat {
            &:nth-child(1) {
              background-color: $white-background;
              z-index: 2;
              left: rem(23px);
              top: rem(0);
              box-shadow: 0px 48px 48px rgba(12, 37, 127, 0.08);

              .profile-description-stat-count {
                color: $white-color-count;
              }

              .profile-description-stat-info {
                color: $white-color-info;
              }
            }

            &:nth-child(2) {
              background-color: $transparent-background;
              border: 1px solid $transparent-border;
              z-index: 1;
              left: rem(113px);
              top: rem(93px);

              .profile-description-stat-count {
                color: $transparent-color-count;
              }

              .profile-description-stat-info {
                color: $transparent-color-info;
              }
            }

            &:nth-child(3) {
              background-color: $green-background;
              z-index: 2;
              left: rem(99px + 124px);
              top: rem(42px);
              animation: pulsation 1s forwards;

              .profile-description-stat-count {
                color: $green-color-count;
              }

              .profile-description-stat-info {
                color: $green-color-info;
              }
            }
          }
        }

        &.white-grey-orange {
          background-position: left top;
          min-height: rem(314px);

          .profile-description-stat {
            &:nth-child(1) {
              background-color: $white-background;
              z-index: 1;
              left: 0;
              top: rem(48px);
              box-shadow: 0px 48px 48px rgba(12, 37, 127, 0.08);

              .profile-description-stat-count {
                color: $white-color-count;
              }

              .profile-description-stat-info {
                color: $white-color-info;
              }
            }

            &:nth-child(2) {
              background-color: $grey-background;
              z-index: 1;
              left: rem(99px);
              top: rem(185px);

              .profile-description-stat-count {
                color: $grey-color-count;
              }

              .profile-description-stat-info {
                color: $grey-color-info;
              }
            }

            &:nth-child(3) {
              background-color: $orange-background;
              z-index: 2;
              left: rem(86px + 124px);
              top: rem(74px);
              animation: pulsation 1s forwards;

              .profile-description-stat-count {
                color: $orange-color-count;
              }

              .profile-description-stat-info {
                color: $orange-color-info;
              }
            }
          }
        }

        .profile-description-stat {
          position: absolute;
          width: rem(124px);
          height: rem(124px);
          border-radius: 4px;
          padding: rem(5px);

          display: flex;
          justify-content: center;
          flex-direction: column;

          > * {
            text-align: center;
            width: 100%;
            display: block;
          }

          .profile-description-stat-count {
            &.regular {
              font-size: rem(24px);
              line-height: rem(24px);
            }

            &.super-small {
              font-size: rem(16px);
              line-height: rem(16px);
            }

            &.small {
              font-size: rem(20px);
              line-height: rem(20px);
            }

            &.big {
              font-size: rem(36px);
              line-height: rem(36px);
            }
          }

          .profile-description-stat-info {
            margin-top: rem(2px);
            font-size: rem(12px);
            line-height: rem(12px);

            &.super-small {
              font-size: rem(10px);
              line-height: rem(10px);
            }
          }
        }
      }
    }
  }
}
