@import 'styles/utils/index';

.forgot-password-template-skeleton-wrapper {
  margin: rem(120px auto 0);

  .forgot-password-template-skeleton-title {
    color: transparent;
    width: max-content;
    margin: 0 auto rem(42px);
    font-size: rem(36px);
    @include skeleton;
  }

  .forgot-password-template-skeleton-form {
    display: grid;
    grid-template-columns: auto;
    grid-row-gap: rem(24px);
    max-width: rem(500px);
    margin: rem(0 auto 16px);

    .forgot-password-template-skeleton-item {
      width: 100%;
      height: rem(66px);
      @include skeleton;
    }
  }

  .forgot-password-template-skeleton-link {
    color: transparent;
    font-size: rem(14px);
    display: block;
    margin: rem(4px auto);
    width: max-content;
    @include skeleton;
  }
}
