@import 'styles/utils/index';

.articles-simple {
  padding: 0 rem(15px);

  @include mq($until: extra-wide) {
    padding: 0 rem(15px);
  }

  .articles-simple__layout {
    max-width: 73.696969697rem;
    margin: 0 auto;
  }

  .book-demo {
    padding: rem(24px 24px 38px);
    max-width: rem(710px);
    margin: rem(36px 50px 36px 0);

    @include mq($until: tablet) {
      margin-right: 0;
    }
  }

  .blog-note {
    img {
      max-width: rem(1100px) !important;
      height: auto !important;
      min-height: auto !important;
    }
  }

  .articles-benefits-wrapper {
    margin: rem(192px 0 -191.6px);

    &.mb-0 {
      margin-bottom: 0 !important;
    }

    @include mq($until: tablet) {
      margin: rem(192px 0 -47.6px);
    }
  }

  .articles-years__benefits {
    @include mq($until: tablet) {
      margin-top: rem(40px);
    }
  }

  img {
    width: 100%;
    height: auto !important;
    object-fit: contain;
  }

  .articles-simple__content {
    z-index: 1;
    position: relative;
    max-width: rem(1100px);
    width: 100%;
    margin: 0 auto;

    &:not(.articles-post-text.articles-collapse-book-demo, .articles-post-text.articles-collapse-find-more) {
      .articles-collapse-book-demo,
      .articles-collapse-find-more {
        margin-top: rem(192px);
      }
    }
  }

  .articles-collapse-block {
    .title-arrow {
      fill: none !important;
      font-size: rem(18px);
      align-self: center;

      path {
        stroke: $main-500;
      }

      &.collapsed {
        transition: 0.2s ease;
        transform: rotate(90deg);
      }
    }
  }

  .articles-simple__article {
    display: flex;
    justify-content: space-between;

    @include mq($until: desktop) {
      flex-direction: column-reverse;
    }
  }

  .articles-post-wrapper {
    width: 100%;

    .articles-post-text {
      display: flex;
      flex-direction: column;
      margin-right: rem(50px);

      * {
        font-family: $font !important;
      }

      & > :not(.articles-collapse-book-demo, .articles-collapse-find-more) {
        max-width: rem(745px);
        width: 100%;
        margin: rem(0 auto 24px);
        color: $font-3;

        @include mq($until: desktop) {
          max-width: none;
        }
      }

      @include mq($until: wide) {
        margin: 0 auto;
      }

      .articles-collapse-book-demo {
        margin-top: rem(30px) !important;

        h1 {
          font-size: rem(56px);
        }

        a {
          color: $main-500;
        }
      }

      .articles-collapse-find-more {
        margin-top: rem(30px) !important;
      }

      h2 {
        margin-top: rem(16px);
        font-size: rem(24px);
        text-align: left;
        line-height: rem(29.16px);
        color: $font-4;
      }

      h3 {
        margin-top: rem(16px);
        font-size: rem(20px);
        line-height: rem(27.2px);
        text-align: left;
        font-weight: 500;
        color: $font-4;
      }

      h4 {
        color: $font-4;
      }

      h2,
      h3,
      h4 {
        a {
          color: $font-4;
        }
      }

      strong {
        color: $font-3;
      }

      a {
        color: $main-500;
        font-weight: 500;

        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }

      ol {
        padding-left: rem(15px);
      }

      ul {
        list-style-type: unset;
      }

      ol,
      ul {
        margin-bottom: rem(15px);
        margin-left: rem(26px) !important;

        h2 {
          margin: 0;
        }

        h3 {
          margin: 0;
        }

        li {
          font-size: rem($text-body-font-1);
          line-height: rem($text-body-height-1);

          &::marker {
            color: $font-4;
          }

          &:not(:last-child) {
            margin-bottom: rem(10px);
          }
        }
      }

      img {
        margin-bottom: rem(24px);
        max-width: rem(618px) !important;
        height: auto !important;
        margin-left: auto;
        margin-right: auto;

        @include mq($until: tablet) {
          margin-top: rem(40px);
          width: 100% !important;
        }

        @include mq($until: desktop) {
          max-width: rem(1010px) !important;
          width: 100% !important;
        }
      }

      p {
        margin: rem(0 0 24px);
        font-size: rem($text-body-font-1);
        line-height: rem($text-body-height-1);
        text-align: left;

        @include mq($until: desktop) {
          max-width: none;
        }
      }

      @include mq($until: desktop) {
        margin-top: rem(55px);
      }

      iframe {
        width: 100%;
      }
    }

    @include mq($until: desktop) {
      flex-direction: column-reverse;
      align-items: center;
    }
  }
}
