@import 'styles/utils/index';

.form-request {
  border: 1px solid $grey-100;
  border-radius: rem(2px);
  padding: rem(52px);
  max-width: rem(488px);

  h3 {
    font-weight: 700;
    font-size: rem(32px);
    line-height: rem(40px);
    color: $font-4;
    margin: 0;
  }

  form > * {
    &:not(:first-child) {
      margin-top: rem(32px);
    }
  }

  .input-wrapper {
    margin-top: rem(32px);
    max-width: 100%;
    width: 100%;

    input {
      width: 100% !important;
    }
  }

  button {
    width: 100%;
    margin-top: rem(32px);

    @include mq($until: small-tablet) {
      margin-top: rem(15px);
    }
  }

  .sub-button-text {
    text-align: left;
    margin-top: rem(8px);
    font-size: rem(16px);
    color: $font-3;

    a {
      color: $font-5;

      &:hover {
        opacity: 0.8;
      }

      &:focus {
        @include focus;
      }
    }
  }

  @include mq($until: small-tablet) {
    padding: rem(52px 20px);
  }
}

.form-request-bg {
  background: $white-secondary;
}
