@import 'styles/utils/index';

.about-us-page {
  .about-infoBlock {
    @include mq($until: iphone-11) {
      padding: rem(0 20px);

      h1 {
        margin: 0;
      }
    }
  }
  .info-block__wrapper {
    button {
      display: none;
    }
  }

  .video-container {
    justify-content: space-between;

    &__info {
      max-width: rem(380px);
      width: 100%;

      @include mq($until: wide) {
        text-align: center;
        max-width: rem(600px);
        width: 100%;
        padding-bottom: rem(20px);
      }

      @include mq($until: desktop) {
        margin: 0;
      }
    }

    &__video {
      max-width: rem($layout-base-2);
      width: 100%;
    }
  }

  .primary-goal {
    display: flex;
    max-width: rem(958.24px);
    width: 100%;
    margin: rem(92px auto 0);

    @include mq($until: desktop) {
      flex-direction: column;
      align-items: center;
      text-align: center;

      > div {
        margin-top: rem(20px);

        @include mq($until: desktop) {
          margin-top: rem(70px);
        }
      }
    }

    @include mq($until: tablet) {
      padding: rem(0 20px);
    }

    @include mq($until: iphone-11) {
      margin-top: rem(12px);
    }

    p {
      margin: 0;
      letter-spacing: 0.01em;
      max-width: rem(700px);
      font-size: rem(24px);
      font-weight: 400;
      line-height: rem(39.36px);
      color: $font-3;

      @include mq($until: iphone-11) {
        text-align: start;
        font-size: rem(16px);
        line-height: rem(24px);
      }
    }

    .about-us-upper__bg {
      display: flex;
      justify-content: center;

      .icon {
        position: absolute;
        margin: rem(-55px 0 0 262px);
      }

      svg {
        font-size: rem(227px) !important;
        width: rem(227px);
        height: rem(276px);
        fill: none;
      }

      @include mq($until: tablet) {
        svg {
          width: rem(100px);
          height: rem(100px);
        }
      }

      @include mq($until: desktop) {
        .icon {
          position: static;
          margin-left: rem(0);
        }
      }
    }
  }

  .our-values {
    background: $white-secondary;
    margin: rem(192px 0 0);
    padding: rem(0 20px 192px);

    @include mq($until: small-desktop) {
      margin: rem(192px 0 0) !important;
    }

    @include mq($until: iphone-11) {
      margin: rem(112px 0 0) !important;

      padding: rem(0 20px 56px) !important;
    }
  }
  .our-vision {
    margin: rem(192px auto 0);
    max-width: rem(1008px);

    @include mq($until: iphone-11) {
      margin: rem(56px auto 0);
    }

    &__description {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      @include mq($until: small-desktop) {
        padding: rem(0 20px);
      }

      h2 {
        font-size: rem(48px);
        line-height: rem(58.32px);
        max-width: rem(384px);
        color: $font-4;
        margin: 0;
      }

      p {
        font-size: rem(40px);
        line-height: rem(54.4px);
        max-width: rem(680px);
        color: $font-3;
        letter-spacing: 0.01em;
        margin: 0;
      }

      @include mq($until: small-desktop) {
        flex-direction: column;

        h2 {
          text-align: center;
          margin: rem(0 auto 16px);
        }

        p {
          font-size: rem(25px);
          text-align: center;
          margin: rem(0 auto 0);
        }
      }

      @include mq($until: iphone-11) {
        h2 {
          text-align: start;
          font-size: rem(32px);
          line-height: rem(36px);
          margin: rem(0 0 12px);
        }

        p {
          font-size: rem(16px);
          line-height: rem(20px);
          margin: rem(0);
          text-align: start;
        }
      }
    }
  }

  .our-values {
    background: $white-secondary;
    margin: rem(192px 0 0);
    min-height: rem(2110px);
    padding: rem(0 20px 192px);

    @include mq($until: iphone-11) {
      min-height: inherit;
    }

    &__title {
      margin: 0 auto;
      max-width: rem(1008px);
      padding-top: rem(274px);
      display: flex;
      flex-direction: column;

      h2 {
        margin: rem(0 0 16px);
        font-size: rem(48px);
        color: $font-4;
      }

      p {
        margin: 0;
        color: $font-3;
        font-size: rem(24px);
        line-height: rem(39.36px);
        max-width: rem(1008px);
      }

      @include mq($until: small-desktop) {
        h2,
        p {
          text-align: center;
        }
      }

      @include mq($until: iphone-11) {
        padding-top: rem(56px);
        margin: rem(56px 0 0);

        h2 {
          margin: rem(0 0 12px);
          text-align: start;
          font-size: rem(28px);
          line-height: rem(32px);
        }

        p {
          text-align: start;
          font-size: rem(16px);
          line-height: rem(20px);
        }
      }
    }

    &__wrapper {
      display: flex;
      max-width: rem(1008px);
      width: 100%;
      margin: rem(104px auto 188px);

      @include mq($until: small-desktop) {
        flex-direction: column;
        align-items: center;
        margin: rem(32px 0 188px);
      }

      @include mq($until: iphone-11) {
        flex-direction: column;
        align-items: center;
        margin: rem(32px 0 56px);
      }

      h4 {
        margin: rem(16px 0);
        color: $font-4;
      }

      span {
        font-size: rem($text-body-font-1);
        line-height: rem($text-body-height-1);
        color: $font-3;
      }

      .our-values__bg-circles {
        position: absolute;
        z-index: 5;
        margin-top: rem(-20px);
        margin-left: rem(-105px);

        .gdb-icon-circles-background {
          font-size: rem(955px);
          height: rem(1042px);
          max-height: rem(1042px);
        }

        @include mq($until: desktop) {
          display: none;
        }
      }
    }

    &__left,
    &__right {
      display: flex;
      flex-direction: column;
      width: 50%;
      z-index: 6;

      @include mq($until: small-tablet) {
        width: 100%;
      }

      @include mq($until: iphone-11) {
        h4 {
          font-size: rem(24px);
          line-height: rem(28px);
          margin-bottom: rem(12px);
        }

        span {
          font-size: rem(16px);
          line-height: rem(20px);
        }
      }
    }

    &__left {
      max-width: rem(488px);
      margin-right: rem(16px);
      @include mq($until: small-desktop) {
        margin-right: rem(0);
      }

      > div:nth-child(1) {
        margin-top: 0;
      }

      > div {
        margin-top: rem(247px);

        @include mq($until: iphone-11) {
          margin-top: rem(56px);
        }
      }

      div:nth-child(1) {
        span:nth-child(3) {
          max-width: rem(488px) !important;
        }
      }
    }

    &__right {
      display: flex;
      max-width: rem(488px);
      flex-direction: column;
      justify-content: center;
      margin-left: rem(16px);

      @include mq($until: small-desktop) {
        margin-top: rem(247px);
      }

      @include mq($until: small-desktop) {
        margin-left: rem(0);
      }

      @include mq($until: iphone-11) {
        margin-top: rem(56px);
      }

      > div:not(:first-child) {
        margin-top: rem(247px);

        @include mq($until: iphone-11) {
          margin-top: rem(56px);
        }
      }
    }

    &__blocks {
      @include mq($until: small-desktop) {
        margin-top: rem(50px) !important;
      }
    }
  }

  .request-demo {
    margin: rem(-180px auto 0);

    @include mq($until: small-desktop) {
      margin: rem(-130px auto 0);
      height: rem(270px);
    }
  }

  .about-us-feedback {
    background: $white-secondary;
    padding-bottom: rem(204px);
    margin: rem(0 0 -190px);

    @include mq($until: small-desktop) {
      margin: rem(0 0 -190px);
    }
    @include mq($until: tablet) {
      margin: rem(0 0 -50px);
    }

    .feedback-container {
      margin-top: rem(164px);
    }

    .feedback-container__left {
      .feedback-container__info {
        @include mq($until: desktop) {
          text-align: center;
          max-width: rem(500px);
          width: 100%;
          padding: 0 rem(15px);
        }
      }

      .feedback-container__block {
        margin-left: rem(70px);

        @include mq($until: desktop) {
          margin-left: rem(16px);
          margin-top: rem(80px);
        }
      }
    }
  }

  .our-team {
    &__title {
      text-align: center;
      max-width: rem(592px);
      width: 100%;
      margin: 0 auto;

      h2 {
        margin: 0 0 rem(16px);
        color: $font-4;
      }

      span {
        font-size: rem(20px);
      }
    }
  }

  .our-benefits__title {
    @include mq($until: desktop) {
      margin-bottom: rem(60px);
    }
  }
}
